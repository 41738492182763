import styled from  'styled-components'

export const Iframe = styled.iframe`
    margin-top: 20px;
    border:0px;
    padding: 0px 10px;
    margin-bottom: 160px;    
    @media (max-width: 768px) 
    {
        margin-top: -10px;
        margin-bottom: 32px;
    }
`;