import React, { useEffect } from "react";
import CareersBanner from "../../components/DataComponents/CareersBanner/CareersBanner";
import Section from "../../components/Section/Section";
import Box from "@mui/material/Box";
import { Trans, useTranslation } from "react-i18next";
import Card from "components/Cards/Card/Card";
import HighlightLink from "components/Hightlight/HighlightLink";
//import { Grid } from "@mui/material";
import {
  List,
  ListItem,
  BenefitGrid,
  GaleryWall,
  GaleryWallRow,
} from "./CareersPage.styled";
import { TitleHighlight } from "components/Section/Section.styled";
import SubSection from "components/SubSection/SubSection";
import Image from "components/Image/Image";
import useDevice from "hooks/useDevice";

//Benefits
import imgBenefit1 from "assets/images/pages/careers/65eb1797ee9e704a1b9d619b_iStock-149079184 (1).jpg";
import imgBenefit2 from "assets/images/pages/careers/65e9aca8002260b40cd996ef_iStock-846081796-p-500.jpg";
import imgBenefit3 from "assets/images/pages/careers/65e88bd1ef13b1660209453b_iStock-1393379238.jpg";
import imgBenefit4 from "assets/images/pages/careers/65e88bd13951b585b5bdb068_iStock-1322842973.jpg";

//Culture
import imgCulture1 from "assets/images/pages/careers/668bef23f310b0fd5e7b23e2_unique career.png";
import imgCulture2 from "assets/images/pages/careers/668bef23ae7165db21c6e895_Family-frendly.png";
import imgCulture3 from "assets/images/pages/careers/668bef23a03e177b8b487188_various-activities.png";

//Galery wall
import imgGaleryWall1 from "assets/images/pages/careers/galery-wall1.jpg";
import imgGaleryWall2 from "assets/images/pages/careers/galery-wall2.jpg";
import imgGaleryWall3 from "assets/images/pages/careers/galery-wall3.jpg";
import imgGaleryWall4 from "assets/images/pages/careers/galery-wall4.jpg";
import imgGaleryWall5 from "assets/images/pages/careers/galery-wall5.jpg";
import imgGaleryWall6 from "assets/images/pages/careers/galery-wall6.jpg";

//Galery wall
import imgSelectionProcess1 from "assets/images/pages/careers/selection-process1.jpg";
import imgSelectionProcess2 from "assets/images/pages/careers/selection-process2.jpg";
import imgSelectionProcess3 from "assets/images/pages/careers/selection-process3.jpg";
import imgSelectionProcess4 from "assets/images/pages/careers/selection-process4.jpg";
import imgSelectionProcess5 from "assets/images/pages/careers/selection-process5.jpg";
import CareersSelectionProcess from "components/DataComponents/CareersSelectionProcess/CareersSelectionProcess";
import CareersSelectionProcessCard from "components/DataComponents/CareersSelectionProcess/CareersSelectionProcessCard/CareersSelectionProcessCard";
import i18next from "i18next";

const CareersPage = () => {
  const { isMobile, isTablet } = useDevice();
  const { t } = useTranslation();
  useEffect(() => {
    document.title = i18next.t("pageTitles.Career");
  }, []);
  const careersBenefits = [
    {
      image: imgBenefit1,
      title: <Trans i18nKey="careersPage.paragraf1.card1.header" />,
      text: (
        <Trans
          i18nKey="careersPage.paragraf1.card1.text"
          components={{
            ul: <List />,
            li: <ListItem />,
          }}
        />
      ),
      bgColor: "secondaryOpaque",
    },
    {
      image: imgBenefit2,
      title: <Trans i18nKey="careersPage.paragraf1.card2.header" />,
      text: (
        <Trans
          i18nKey="careersPage.paragraf1.card2.text"
          components={{
            ul: <List />,
            li: <ListItem />,
          }}
        />
      ),
      bgColor: "thertiary",
    },
    {
      image: imgBenefit3,
      title: <Trans i18nKey="careersPage.paragraf1.card3.header" />,
      text: (
        <Trans
          i18nKey="careersPage.paragraf1.card3.text"
          components={{
            ul: <List />,
            li: <ListItem />,
          }}
        />
      ),
      bgColor: "thertiary",
    },
    {
      image: imgBenefit4,
      title: <Trans i18nKey="careersPage.paragraf1.card4.header" />,
      text: (
        <Trans
          i18nKey="careersPage.paragraf1.card4.text"
          components={{
            ul: <List />,
            li: <ListItem />,
          }}
        />
      ),
      bgColor: "white",
    },
  ];

  const careersCulture = [
    {
      image: imgCulture1,
      text: "careersPage.paragraf2.items.item1",
      reverse: true,
      height: "486px",
    },
    {
      image: imgCulture2,
      text: "careersPage.paragraf2.items.item2",
      reverse: false,
      height: "489px",
    },
    {
      image: imgCulture3,
      text: "careersPage.paragraf2.items.item3",
      reverse: true,
      height: "489px",
    },
  ];
  const careersSelectionProcess = [
    {
      image: imgSelectionProcess1,
      header: "careersPage.paragraf3.items.item1.header",
      text: "careersPage.paragraf3.items.item1.text",
      bgColor: "white",
    },
    {
      image: imgSelectionProcess2,
      header: "careersPage.paragraf3.items.item2.header",
      text: "careersPage.paragraf3.items.item2.text",
      bgColor: "thertiary",
    },
    {
      image: imgSelectionProcess3,
      header: "careersPage.paragraf3.items.item3.header",
      text: "careersPage.paragraf3.items.item3.text",
      bgColor: "thertiary",
    },
    {
      image: imgSelectionProcess4,
      header: "careersPage.paragraf3.items.item4.header",
      text: "careersPage.paragraf3.items.item4.text",
      bgColor: "thertiary",
    },
    {
      image: imgSelectionProcess5,
      header: "careersPage.paragraf3.items.item5.header",
      text: "careersPage.paragraf3.items.item5.text",
      bgColor: "secondaryOpaque",
    },
  ];
  return (
    <>
      <Section bgColor="white">
        <CareersBanner />
      </Section>
      <Section
        bgColor="dark"
        titleAlignLeft={false}
        cardTitle={t("careersPage.paragraf1.header")}
        margin={isMobile ? "64px 0px 32px" : "120px 0 80px"}
        paddingBottom={isMobile ? "10px" : "20px"}
      >
        <BenefitGrid container sx={{ display: "flex" }}>
          {careersBenefits?.map?.((card, index) => (
            <Card
              key={index}
              sx={{ order: 0 }}
              image={card.image}
              title={card.title}
              bgColor={card.bgColor}
            >
              {card.text}
            </Card>
          ))}
        </BenefitGrid>
      </Section>
      <Section
        isStyled={true}
        bgColor="white"
        titleAlignLeft
        cardTitle={
          <Trans
            t={t}
            components={[<TitleHighlight isStyled={true} key={0} />]}
            i18nKey="careersPage.paragraf2.header"
          />
        }
        margin={isMobile ? "64px 0px 6px" : "120px 0px 37px"}
        paddingBottom={isMobile ? "10px" : "20px"}
      >
        {careersCulture?.map?.((item1, index) => (
          <SubSection
            key={index}
            text={item1.text}
            image={item1.image}
            reverse={item1.reverse}
            textColor={"thirdTextColor"}
            HighlightColor={"secondaryOpaque"}
            isDark={false}
            height={item1.height}
            marginTop="27px"
          ></SubSection>
        ))}
        <GaleryWall>
          <GaleryWallRow $height={360}>
            <Image image={imgGaleryWall1} alt="" height={"100%"} />
            <Image image={imgGaleryWall2} alt="" height={"100%"} />
            <Image image={imgGaleryWall3} alt="" height={"100%"} />
          </GaleryWallRow>
          <GaleryWallRow $height={270}>
            <Image image={imgGaleryWall4} alt="" height={"100%"} />
            <Image image={imgGaleryWall5} alt="" height={"100%"} />
            <Image image={imgGaleryWall6} alt="" height={"100%"} />
          </GaleryWallRow>
        </GaleryWall>
      </Section>
      <Section
        bgColor="dark"
        titleAlignLeft={false}
        cardTitle={
          <Trans
            t={t}
            i18nKey="careersPage.paragraf3.header"
            components={[<TitleHighlight isStyled={true} key={0} />]}
          />
        }
        margin={isMobile ? "64px 0px 32px" : "120px 0px 80px"}
        paddingBottom={isMobile ? "0px" : "36px"}
      >
        {!(isMobile || isTablet) &&
          careersSelectionProcess?.map?.((item1, index) => (
            <CareersSelectionProcess
              key={index}
              text={item1.text}
              image={item1.image}
              header={item1.header}
              bgColor={item1.bgColor}
              IsEvenRow={index % 2 == 0}
            ></CareersSelectionProcess>
          ))}
        {(isMobile || isTablet) &&
          careersSelectionProcess?.map?.((item1, index) => (
            <CareersSelectionProcessCard
              key={index}
              text={item1.text}
              image={item1.image}
              header={item1.header}
              bgColor={item1.bgColor}
              IsEvenRow={index % 2 == 0}
            ></CareersSelectionProcessCard>
          ))}
      </Section>
      <Section
        bgColor="white"
        titleAlignLeft
        cardTitle={
          <Trans
            t={t}
            i18nKey="careersPage.paragraf4.header"
            components={[<TitleHighlight isStyled={true} key={0} />]}
          />
        }
        margin={isMobile ? "60px 0 36px" : "120px 0 60px"}
        paddingBottom={isMobile ? "13px" : "35px"}
      >
        <Box sx={{ pb: { xs: "172px", md: "145px" }, px: 0 }}>
          <HighlightLink
            color={"thertiary"}
            isStyled={false}
            url={"mailto:hr@tremium.net"}
            text={"careersPage.paragraf4.text"}
          ></HighlightLink>
        </Box>
      </Section>
    </>
  );
};

export default CareersPage;
