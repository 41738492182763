import React from "react";
import { ReactComponent as FacebookIcon } from "../../../static/facebook_icon.svg";
import { ReactComponent as InstagramIcon } from "../../../static/insta_icon.svg";
import { ReactComponent as LinkedinIcon } from "../../../static/linkedin_icon.svg";
import { SocialNetworkContainer, SocialNetwork } from "../Footer.style";

const SocialNetworks = () => {
  return (
    <SocialNetworkContainer>
      <SocialNetwork
        target="_blank"
        href="https://www.instagram.com/tremium_software/"
      >
        <InstagramIcon />
      </SocialNetwork>
      <SocialNetwork
        target="_blank"
        href="https://www.linkedin.com/company/tremium-software23/mycompany/"
      >
        <LinkedinIcon />
      </SocialNetwork>
      <SocialNetwork
        target="_blank"
        href="https://www.facebook.com/profile.php?id=61554289124261"
      >
        <FacebookIcon />
      </SocialNetwork>
    </SocialNetworkContainer>
  );
};
export default SocialNetworks;
