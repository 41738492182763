import React from "react";
import PropTypes from "prop-types";
import {
  PeopleCardContainer,
  PeopleCardDetails,
  PeopleCardTitle,
  PeopleCardSubTitle,
  PeopleCardImage,
  PeopleCardImageCont,
  PeopleCardOverlay
} from "./PeopleCard.styled";
const PeopleCard = ({ title, subTitle, image, backgroundType, children, bgColor = "white" }) => {
  return (
    <PeopleCardContainer $bgColor={bgColor}>
      <PeopleCardImageCont>
        <PeopleCardImage component="img" image={image} alt={title} />
        <PeopleCardOverlay backgroundType={backgroundType} />
      </PeopleCardImageCont>
      <PeopleCardDetails sx={{ width: "100%" }}>
        <PeopleCardTitle sx={{ fontSize: 18 }}>
          {title}
        </PeopleCardTitle>
        <PeopleCardSubTitle>
          {subTitle}
        </PeopleCardSubTitle> 
        {children}
      </PeopleCardDetails>      
    </PeopleCardContainer>
  );
};
PeopleCard.propTypes = {
  bgColor: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  image: PropTypes.string,
  children: PropTypes.node,
  backgroundType: PropTypes.any
};
export default PeopleCard;
