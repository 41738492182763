import styled, { css } from "styled-components";
import { Box } from "@mui/material";

export const Highlight = styled.span`
  color: ${(props) =>
    props.isText
      ? props?.theme?.colors?.dark
      : props?.theme?.colors?.secondaryOpaque};

  background-color: ${(props) =>
    props.isText
      ? props?.theme.colors[props?.color ?? "white"]
      : "transparent"};

  font-weight: ${(props) =>
    props.IsBold ? (props.isText ? "500" : "800") : "400"};
  padding: ${(props) =>
    props.applyPadding !== undefined && props.applyPadding ? "4px" : "0px"};
  ${(props) =>
    props.isText &&
    css`
      // line-height: 36px;
      font-stretch: expanded;
      letter-spacing: -0.9px;
    `}
  ${(props) =>
    !props.isText &&
    css`
      white-space: nowrap;
    `}
`;
export const StyledTitle = styled.span`
  color: ${(props) =>
    props.isStyled
      ? props?.theme?.colors?.secondTextColor
      : props?.theme?.colors?.thirdTextColor};
`;

export const HighlightLinkContener = styled(Box)`
  color: ${(props) => props?.theme?.colors?.dark};

  & ${Highlight} {
    background-color: ${(props) => props?.theme?.colors[props.$color]};
  }

  @media (max-width: 512px) {
    & ${Highlight} {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    //margin-top: 4px;
    font-size: 14px;
    line-height: 21px;
  }
`;
export const Link = styled.a`
  position: relative;
  font-weight: 900;
  text-underline-offset: 5px;
  z-index: 5;
  color: ${(props) => props?.theme?.colors?.dark};

  @media (max-width: 512px) {
    position: relative;
    text-underline-offset: initial;
  }
  @media (max-width: 1024px) {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }
`;

export const HighlightText = styled.span`
  color: transparent;
  position: absolute;
  margin-top: 10px;

  margin-left: 2px;
  z-index: 0;

  & ${Highlight} {
    color: transparent;
  }

  @media (max-width: 1024px) {
    margin-top: 4px;
    font-size: 14px;
    line-height: 21px;
  }
`;
