import React from "react";
import milan_djordjevic from "../../static/milan_djordjevic.png";
import marko_djurovic from "../../static/marko_djurovic.png";
import strahinja_djurkovic from "../../static/strahinja_djurkovic.png";
import dunja_stefanovic from "../../static/dunja_stefanovic3.png";
import miljan_stefanovic from "../../static/miljan_stefanovic.png";
import i18next from "i18next";
import { BackgroundType } from "constants/backgroundType";
import Team from "./Team";
import useDevice from "hooks/useDevice";
const BusinessTeam = () => {
  const { isMobile } = useDevice();
  const team = [
    {
      title: i18next.t("aboutPage.ourTeam.Business.Milan"),
      subTitle: i18next.t("aboutPage.ourTeam.Business.Role"),
      image: milan_djordjevic,
      backgroundType: BackgroundType.BLUE,
    },
    {
      title: i18next.t("aboutPage.ourTeam.Business.Miljan"),
      subTitle: i18next.t("aboutPage.ourTeam.Business.Role"),
      image: miljan_stefanovic,
      backgroundType: BackgroundType.YELLOW,
    },
    {
      title: i18next.t("aboutPage.ourTeam.Business.Marko"),
      subTitle: i18next.t("aboutPage.ourTeam.Business.Role"),
      image: marko_djurovic,
      backgroundType: BackgroundType.DARK_BLUE,
    },
    {
      title: i18next.t("aboutPage.ourTeam.Business.Strahinja"),
      subTitle: i18next.t("aboutPage.ourTeam.Business.Role"),
      image: strahinja_djurkovic,
      backgroundType: BackgroundType.BLUE,
    },
    {
      title: i18next.t("aboutPage.ourTeam.Business.Dunja"),
      subTitle: i18next.t("aboutPage.ourTeam.Business.Role"),
      image: dunja_stefanovic,
      backgroundType: BackgroundType.YELLOW,
    },
  ];
  return (
    <Team
      margin={isMobile ? "36px 0 10px" : "99px 10px 0px"}
      paddingBottom={isMobile ? "10px" : "20px"}
      title="aboutPage.ourTeam.Business.Title"
      team={team}
      titleAlignLeft={true}
    />
  );
};
export default BusinessTeam;
