import React from 'react'
import { Iframe } from './GoogleMap.style'
function GoogleMap() {
  return (
    <Iframe 
      src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1220.4654481970256!2d21.894575733208356!3d43.319023953427354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDPCsDE5JzA4LjIiTiAyMcKwNTMnNDIuMCJF!5e0!3m2!1sen!2srs!4v1710439912151!5m2!1sen!2srs"
      width="100%" 
      height="450" 
      allowfullscreen="" 
      loading="lazy" />
  )
}
export default GoogleMap;