import styled from "styled-components";
import { Grid } from "@mui/material";
import { ImageContainer, ImageElement } from "components/Image/Image.styled";

export const List = styled.ul`
  padding-top: 0;
  padding-left: 20px;
  margin: 0;
  font-size: 20px;
  line-height: 90%;
  list-style-type: disc;
  @media (max-width: 959px) {
    font-size: 14px;
  }
`;
export const ListItem = styled.li`
  padding: 0;
  margin: 0 0 10px 0;
  width: 100%;
  line-height: 115%;
`;

export const BenefitGrid = styled(Grid)`
  justify-content: center;
  margin: 0px 0px 152px;
  gap: 16px;
  @media (max-width: 1170px) {
    gap: 25px;
    padding-right: 150px;
    padding-left: 150px;
  }
  @media (max-width: 959px) {
    margin-bottom: 27px;
  }
`;

export const GaleryWall = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  margin-bottom: 64px;
  margin-top: 116px;
  @media (max-width: 1170px) {
    gap: 10px;
    margin: 27px 0 32px;
  }
`;

export const GaleryWallRow = styled.div`
  display: flex;
  flex-direction: row;
  height: ${(props) => props?.$height}px;
  flex-wrap: nowrap;
  gap: 16px;
  & ${ImageContainer} {
    margin-right: 0px;
    margin-left: 0px;
  }

  @media (max-width: 1170px) {
    gap: 10px;
    height: calc(${(props) => props?.$height} / 1170 * 100vw);
    & ${ImageElement} {
      border-radius: 6px;
    }
  }
`;
