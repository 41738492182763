import { ButtonContainer } from "../../components/Button/ActionButton/ActionButton.styled";
import styled from "styled-components";

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  float: left;
  background-color: ${(props) => props?.theme.colors.secondaryOpaque};
  border-radius: 20px;
  padding: 30px;
  & ${ButtonContainer} {
    margin-top: 15px;
    cursor: pointer;
  }
`;
export const FormGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
`;
export const Label = styled.label`
  color: ${(props) => props?.theme.colors?.dark};
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.74px;
  text-decoration: none;
`;
export const Required = styled.span`
  color: ${(props) => props?.theme.colors?.red};
`;
export const Input = styled.input`
  &[type="text"] {
    grid-column-gap: 10.298px;
    background-color: ${(props) => props?.theme.colors.white};
    color: ${(props) => props?.theme.colors.thirdTextColor};
    border: 1.28725px solid #d0d5dd;
    border-radius: 1000px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 12.8725px 18.0215px;
    text-decoration: none;
    display: flex;
    min-height: 44.7188px;
  }
  &[type="file"] {
    display: ${(props) => (props.show ? "block" : "none")};
  }
`;
export const TextareaContainer = styled.textarea`
  font-family: "Mona-Sans";
  font-size: 14px;
  color: ${(props) => props?.theme.colors.thirdTextColor};
  line-height: 20px;
  resize: none;
  border-radius: 24px;
  border: 1.28725px solid #d0d5dd;
  padding: 12.8725px 18.0215px;
  overflow: auto;
  height: 147px;
`;
