import * as React from 'react';
import PropTypes from "prop-types";
import {CustomTabPanelContainer} from './Footer.style'
const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <CustomTabPanelContainer sx={{ p: 3 }}>{children}</CustomTabPanelContainer>}
      </div>
    );
  }
CustomTabPanel.propTypes = {
  index: PropTypes.any,
  value: PropTypes.any,
  children: PropTypes.any,
  other : PropTypes.any
};
export default CustomTabPanel;
