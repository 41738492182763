import styled from "styled-components";
import { Box } from "@mui/material";

export const ImageWithTitle = styled(Box)`
  width: 210px;
  height: 512px;
  background-image: url(${(props) => props?.$image});
  background-size: cover;
  background-position: center;
  border-radius: 16px;
  margin: 80px 0 160px;

  @media (max-width: 1024px) {
    margin: 8px 0 64px;
  }

  @media (max-width: 959px) {
    width: 120px;
    height: 293px;
  }
`;
export const ImageTitle = styled(Box)`
  margin-top: 50px;
  margin-left: 10%;
  width: ${(props) => props?.$width};
  background-color: ${(props) => props?.theme.colors?.thertiary};
  position: relative;
  padding-left: 1%;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  font-stretch: expanded;

  @media (max-width: 959px) {
    margin-top: 20px;
    font-size: 14px;
    line-height: 13px;
    width: calc(${(props) => props?.$width} * 0.55);
  }
`;
export const ImageWithTitleBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  min-width: 648px;
  overflow: auto;
`;

export const HomeOurClientsLogos = styled(Box)`
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template:
    "Area Area-2"
    "Area-3 Area-4"
    "Area-5 Area-6" / 50% 50%;
  grid-auto-columns: 0.25fr;
  align-content: stretch;
  justify-content: center;
  align-items: center;
  justify-items: center;
  width: 100%;
  display: grid;

  margin-bottom: 32px;
`;

export const HomeOurClientsLogo = styled.img`
  margin: 0 20px 10px;
`;
