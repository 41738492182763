import React from "react";
import PropTypes from "prop-types";
import {
  HighlightText,
  Highlight,
  HighlightLinkContener,
  Link,
} from "./Hightlight.style";
import { Trans } from "react-i18next";

const HighlightLink = (props) => {
  return (
    <HighlightLinkContener $color={props.color}>
      <HighlightText>
        <Trans
          components={[<Highlight href={props.url} key={0} />]}
          i18nKey={props.text}
        />
      </HighlightText>
      <Trans
        components={[<Link href={props.url} key={0} />]}
        i18nKey={props.text}
      />
    </HighlightLinkContener>
  );
};

HighlightLink.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  url: PropTypes.string,
  text: PropTypes.string,
};

export default HighlightLink;
