import React from "react";
import Section from "components/Section/Section";
import { FooterContainer, TabContainer, TabsContainer } from "./Footer.style";
import { Highlight } from "../Hightlight/Hightlight.style";
import { FooterTabType } from "../../constants/footerTabType";
import { Trans, useTranslation } from "react-i18next";
import DoBusinessTab from "./DoBusinessTab/DoBusinessTab";
import JoinUsTab from "./JoinUsSection/JoinUsTab";
import FooterBottom from "./FooterBottom";
const Footer = () => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(FooterTabType.JOIN_US);
  const handleSwitch = (event, newValue) => {
    setValue(newValue);
  };
  function additionalProps(index) {
    return {
      id: `footer-tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }
  return (
    <FooterContainer id="footer">
      <Section>
        <TabsContainer
          TabIndicatorProps={{ style: { display: "none" } }}
          value={value}
          onChange={handleSwitch}
          aria-label="contact tabs"
        >
          <TabContainer
            className="tab"
            width={"40%"}
            label={
              <Trans
                t={t}
                i18nKey="footer.tabs.tab1Label"
                components={[
                  <Highlight key={0} isStyled={true} color="dark" />,
                ]}
              />
            }
            {...additionalProps(FooterTabType.JOIN_US)}
          />
          <TabContainer
            className="tab"
            width={"60%"}
            label={
              <Trans
                t={t}
                i18nKey="footer.tabs.tab2Label"
                components={[
                  <Highlight key={0} isStyled={true} color="secondTextColor" />,
                ]}
              />
            }
            {...additionalProps(FooterTabType.DO_BUSINESS)}
          />
        </TabsContainer>
        <JoinUsTab value={value} index={FooterTabType.JOIN_US} />
        <DoBusinessTab value={value} index={FooterTabType.DO_BUSINESS} />
      </Section>
      <FooterBottom />
    </FooterContainer>
  );
};
export default Footer;
