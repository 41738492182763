import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "pages/HomePage/HomePage";
import CareersPage from "pages/CareersPage/CareersPage";
import AboutPage from "pages/AboutPage/AboutPage";
import Error from "pages/ErrorPage/ErrorPage";
import { PAGES } from "constants/pages";

const AppRoutes = () => (
  <Routes>
    <Route errorElement={<Error />}>
      <Route path={PAGES.HOME.route} element={<HomePage />} />
      <Route path={PAGES.BASE.route} element={<HomePage />} />
      <Route path={PAGES.CAREERS.route} element={<CareersPage />} />
      <Route path={PAGES.ABOUT.route} element={<AboutPage />} />
      <Route path={PAGES.CONTACT.route} element={<AboutPage />} />
    </Route>
  </Routes>
);

export default AppRoutes;
