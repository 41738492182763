import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormContainer } from "./CustomForm.style";
import ActionButton from "../../components/Button/ActionButton/ActionButton";
import { ReactComponent as ButtonGrayIcon } from "../../assets/images/svg/arr-right-gray.svg";
import { ReactComponent as ButtonBlackIcon } from "../../assets/images/svg/arr-right.svg";
import FormGroup from "../CustomForm/FormGroup";
import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";
import { postRequest } from "../../request/index";
import { Spinner } from "components/Button/ActionButton/ActionButton.styled";
import i18next from "i18next";

const CustomForm = (props) => {
  const {
    children,
    method,
    action,
    buttonText,
    isValid,
    isSubmited,
    submited,
  } = props;
  const [textareaValue, setTextareaValue] = useState("");
  const handleTextareaChange = (newValue) => {
    setTextareaValue(newValue);
  };
  let [loading, setLoading] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    var data = new FormData(event.target);
    data.append("message", textareaValue);
    let formObject = Object.fromEntries(data.entries());
    setLoading(true);
    postRequest(action, formObject).then((response) => {
      if (response?.status === 200 && response?.data?.isSent) {
        setLoading(false);
        NotificationManager.success(
          i18next.t("footer.message.text"),
          i18next.t("footer.message.title")
        );
        setTextareaValue("");
        submited(true);
      }
    });
  };
  useEffect(() => {
    setLoading(loading);
    // submited(false);
  }, [loading]);
  return (
    <FormContainer
      enctype="multipart/form-data"
      method={method}
      action={action}
      onSubmit={(e) => handleSubmit(e)}
    >
      {children}
      <FormGroup
        isSubmited={isSubmited}
        onTextareaChange={handleTextareaChange}
        name="Message"
        type="message"
        label="Message:"
      />
      <ActionButton
        disabled={!isValid}
        max_width="100%"
        size={"lg"}
        type={"submit"}
      >
        {buttonText}
        {loading ? (
          <Spinner />
        ) : isValid ? (
          <ButtonBlackIcon />
        ) : (
          <ButtonGrayIcon />
        )}
      </ActionButton>
    </FormContainer>
  );
};
CustomForm.propTypes = {
  method: PropTypes.any,
  action: PropTypes.any,
  children: PropTypes.any,
  buttonText: PropTypes.any,
  isValid: PropTypes.bool,
  isSubmited: PropTypes.bool,
  submited: PropTypes.func,
};
CustomForm.defaultProps = {
  buttonText: "Submit",
  method: "post",
};
export default CustomForm;
