import React from "react";
import {
  HomeBannerContainer,
  HomeBannerImageParagraph,
  HomeBannerParagraph,
  HomeBannerParagraph2,
  HomeBannerParagraphHeader,
  HomeBannerParagraphText,
  HomeBannerImage,
  HomeBannerSegment1,
  HomeBannerSegment2,
  HomeBannerBreadcrumb,
  HomeBannerBreadcrumbItem,
  HomeBannerSegmentItem,
  Bold,
} from "./HomeBanner.styled";
import { Trans } from "react-i18next";
import { Box } from "@mui/material";
import HighlightLink from "components/Hightlight/HighlightLink";

import ActionButton from "components/Button/ActionButton/ActionButton";
import { ReactComponent as ButtonIcon } from "assets/images/svg/arr-right.svg";
import imgBanner from "assets/images/pages/home-banner.jpg";
import { ReactComponent as WrapperTL } from "assets/images/svg/vectors_wrapper_top_left_w.svg";
import useDevice from "hooks/useDevice";

const HomeBanner = () => {
  const { isMobile, isTablet } = useDevice();
  return (
    <HomeBannerContainer>
      <HomeBannerImageParagraph>
        <HomeBannerParagraph>
          <Trans
            i18nKey="homePage.intro.header"
            components={{
              1: <HomeBannerParagraphHeader></HomeBannerParagraphHeader>,
              0: <Bold />,
            }}
          />
          <HomeBannerParagraphText>
            <HighlightLink
              color={"thertiary"}
              isStyled={false}
              text={"homePage.intro.text"}
            ></HighlightLink>

            {!(isMobile || isTablet) && (
              <WrapperTL xs={{ display: "none" }}></WrapperTL>
            )}
            <HomeBannerBreadcrumb>
              <Trans
                i18nKey="homePage.intro.breadcrumb"
                components={[<HomeBannerBreadcrumbItem key={0} />]}
              />
            </HomeBannerBreadcrumb>
          </HomeBannerParagraphText>
        </HomeBannerParagraph>
        <HomeBannerImage src={imgBanner} height={465}></HomeBannerImage>
      </HomeBannerImageParagraph>
      <HomeBannerParagraph2>
        <HomeBannerSegment1>
          <HomeBannerSegmentItem>
            <Box
              sx={{
                fontSize: 48,
              }}
            >
              <Trans
                i18nKey="homePage.intro.items.item1.text"
                components={[<Bold key={0} />]}
              />
            </Box>
            <Trans
              i18nKey="homePage.intro.items.item1.header"
              components={[<Bold key={0} />]}
            />
          </HomeBannerSegmentItem>

          <HomeBannerSegmentItem>
            <Box
              sx={{
                fontSize: 59,
              }}
            >
              <Trans
                i18nKey="homePage.intro.items.item2.text"
                components={[<Bold key={0} />]}
              />
            </Box>
            <Trans
              i18nKey="homePage.intro.items.item2.header"
              components={[<Bold key={0} />]}
            />
          </HomeBannerSegmentItem>

          <HomeBannerSegmentItem>
            <Box
              sx={{
                fontSize: 59,
              }}
            >
              <Trans
                i18nKey="homePage.intro.items.item3.text"
                components={[<Bold key={0} />]}
              />
            </Box>
            <Trans
              i18nKey="homePage.intro.items.item3.header"
              components={[<Bold key={0} />]}
            />
          </HomeBannerSegmentItem>
        </HomeBannerSegment1>
        <HomeBannerSegment2
          sx={{
            width: { xs: "100%", lg: 379 },
            height: "100%",
            maxWidth: 766,
          }}
        >
          <ActionButton isLink={true}>
            <Trans i18nKey="homePage.intro.button" />
            <ButtonIcon
              width={isMobile || isTablet ? "18px" : "24px"}
            ></ButtonIcon>
          </ActionButton>
        </HomeBannerSegment2>
      </HomeBannerParagraph2>
    </HomeBannerContainer>
  );
};

export default HomeBanner;
