import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import i18next from "i18next";
import AppRoutes from "./AppRoutes";
import { useTranslation } from "react-i18next";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { StyledEngineProvider } from "@mui/material";
import { authScopeStringGetHelper } from "util/authScopeHelpers";
import { LANGUAGE } from "constants/localStorage";
import Header from "components/Header/Header";
import useBreadcrumbs from "hooks/useBreadcrumbs";
// import { HOME_PAGE } from "constants/pages";
// import { useSelector } from "react-redux";
// import { selectCurrentToken } from "features/auth/authSlice";
import './App.css';
import Footer from "components/Footer/Footer";
import ScrollToTop from '../src/components/ScrollToTop/ScrollToTop'
const App = () => {
  const { i18n } = useTranslation();
  useBreadcrumbs();
  // const navigate = useNavigate();

  // const auth = useSelector(selectCurrentToken);

  useEffect(() => {
    const lang = authScopeStringGetHelper(LANGUAGE);

    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, []);

  // useEffect(() => {
  //   if (auth !== null) {
  //     navigate(HOME_PAGE, { replace: true });
  //   }
  // }, []);

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>{i18next.t("app.title")}</title>
      </Helmet>
      <StyledEngineProvider injectFirst>
        <ToastContainer bodyClassName="ToastBody" />
        <Header />
        <AppRoutes />
        <Footer />
      </StyledEngineProvider>
    </>
  );
};

export default App;
