import React , { useEffect, useState } from 'react';
import CustomTabPanel from '../CustomTabPanel'
import CustomTabPanelLeft from "../CustomTabPanelLeft";
import CustomTabPanelRight from "../CustomTabPanelRight";
import FormGroup from "../../CustomForm/FormGroup";
import CustomForm from 'components/CustomForm/CustomForm';
import { useTranslation } from 'react-i18next';
import apiEndpoints from "request/apiEndpoints";
import PropTypes from "prop-types";
import { Ul, Li } from '../../Ul/Ul.styled'
import { Reason, ReasonIcon} from '../Footer.style'
import reason_icon from '../../../static/reason_icon.svg'
import i18next from 'i18next';
import { validateEmail } from '../../../util/helpers/validateEmail';
const JoinUsTab = (props) => {
    const {value, index } = props;
    const { t } = useTranslation();    
    const joinUsReasons = t('footer.joinUsReasons', { returnObjects: true });
    const [isValid, setIsValid] = useState(false)
    const [isSubmited, setIsSubmited] = useState(false);
    const initialState = {
      Name: '',
      Email: '',
      Attachment: ''
    };
    const [formData, setFormData] = useState(initialState);   
    const handleInputChange = (name, value) => {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    };
    useEffect(() => {
     let  existsError = false;
      for (const [field, value] of Object.entries(formData)) {
        // console.log(field);
        if (!value || value ==='' || (field === "Email" && validateEmail(value) === null)) {
          existsError = true;
          break; 
        }
      }
      setIsValid(!existsError);   
      setIsSubmited(false);   
    }, [formData]);
    const submited = () => {
      setIsSubmited(true);
      setFormData(initialState);
    }
    return (
      <CustomTabPanel value={value} index={index}>
        <CustomTabPanelLeft>
          <CustomForm isValid={isValid}
            action = {apiEndpoints.contact.joinUs} isSubmited={isSubmited} submited={submited} 
            >
            <FormGroup isSubmited={isSubmited} onChange={handleInputChange}
              name = {i18next.t('footer.joinUsForm.fullName.name')} label={i18next.t('footer.joinUsForm.fullName.label')} className = "required" isRequired={true} />
            <FormGroup isSubmited={isSubmited} onChange={handleInputChange}
            name = {i18next.t('footer.joinUsForm.email.name')} label= {i18next.t('footer.joinUsForm.email.label')} className="required" isRequired={true} />                 
            <FormGroup isSubmited={isSubmited} onChange={handleInputChange}
            name =  {i18next.t('footer.joinUsForm.attachment.name')} type="file" accept="application/pdf" label={i18next.t('footer.joinUsForm.attachment.label')} t className ="required" isRequired={true} />                                     
          </CustomForm>
        </CustomTabPanelLeft>
        <CustomTabPanelRight>
            <Ul>
              {
                joinUsReasons.map((reason, i) => (<Li key={i}><ReasonIcon src={reason_icon} alt="icon" /><Reason>{reason}</Reason></Li>))
              }
            </Ul>
        </CustomTabPanelRight>
      </CustomTabPanel>
    );
}
JoinUsTab.propTypes = {
    index: PropTypes.any,
    value: PropTypes.any
};
export default JoinUsTab;