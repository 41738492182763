import { Box } from "@mui/material";
import styled from "styled-components";
import { SubSectionContainerText } from "components/SubSection/SubSection.styled";

export const CareersSelectionProcessCardContainer = styled(Box)`
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  @media (max-width: 768px) {
    &:last-of-type {
      padding-bottom: 57px;
    }
  }
`;

export const CareersSelectionProcessCardParagraph = styled(Box)`
  grid-row-gap: 15px;
  background-color: ${(props) => props?.theme.colors[props?.$color ?? "white"]};
  border-radius: 15.4348px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 712px;
  text-decoration: none;
  display: flex;
  max-width: 70%;
  min-width: 280px;
  height: 280px;
`;

export const CareersSelectionProcessCardParagraphHeader = styled(Box)`
  display: block;
  color: ${(props) => props?.theme.colors?.subTitleColor};
  font-size: 17px;
  font-weight: 800;
  font-stretch: ultra-expanded;
  margin-top: auto;
  padding-top: 14px;
  padding-bottom: 5px;
  margin-left: 15px;
  margin-right: 15px;
`;

export const CareersSelectionProcessCardParagraphText = styled(Box)`
  padding: 0 8px;
  & ${SubSectionContainerText} {
    width: 100%;
    margin: 0;
    max-width: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${(props) => props?.theme.colors.thirdTextColor};
  }
`;

export const CareersSelectionProcessCardImage = styled.div`
  background-image: url(${(props) => props?.$src});
  background-size: cover;
  background-position: center;
  border-top-left-radius: 16px;
  border-bottom-right-radius: 16px;
  width: 84px;
  height: 50px;
`;

export const Bold = styled.span`
  font-weight: 700;
  font-stretch: expanded;
`;
