import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

export const ImageContainer = styled.div`
  text-align: center;
  max-width: calc(100% - 10px);
  margin-right: 10px;
  margin-left: 10px;
  height: auto;
`;
export const ImageElementSimple = styled.img`
  border-radius: 16px;
  max-width: calc(100% - 10px);
  margin-right: 10px;
  margin-left: 6px;
  margin-top: 9px;
  height: auto;
  @media (max-width: 768px) {
    border-radius: 0px;
    margin-top: 0px;
  }
`;
export const ImageElement = styled.img`
  border-radius: 16px;
  height: ${(props) => props.height};
  width: auto;
`;
export const CommitmentImageRight = styled.img`
  position: relative;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  width: 442px;
  height: 320px;
  right: 0;
  float: right;
  @media (max-width: 1200px) {
    border-top-left-radius: 16px;
    border-bottom-right-radius: 0px;
    width: 100%;
    height: unset;
  }
  @media (max-width: 768px) {
    height: unset;
  }
`;
export const CommitmentImageLeft = styled.img`
  position: relative;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  width: 442px;
  height: 320px;
  left: 0px;
  float: left;
  @media (max-width: 1200px) {
    border-top-right-radius: 16px;
    border-bottom-left-radius: 0px;
    width: 100%;
    height: unset;
  }
  @media (max-width: 768px) {
    height: unset;
  }
`;
export const CommitmentImageContainer = styled(Grid)`
  position: relative;
  height: 320px;
  width: 442px;
  height: 320px;
  display: block;
  flex: 1;
  @media (max-width: 1200px) {
    border-top-left-radius: 16px;
    border-bottom-right-radius: 0px;
    width: 100%;
    height: unset;
  }
  @media (max-width: 768px) {
    height: unset;
  }
`;
export const CommitmentImage = ({ ...props }) => {
  return props.reverse ? (
    <CommitmentImageRight src={props.src} />
  ) : (
    <CommitmentImageLeft src={props.src} />
  );
};
CommitmentImage.propTypes = {
  reverse: PropTypes.any,
  src: PropTypes.any,
};
