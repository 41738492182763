import { Box } from "@mui/material";
import styled from "styled-components";
import { SubSectionContainerText } from "components/SubSection/SubSection.styled";
import { ImageElement, ImageContainer } from "components/Image/Image.styled";
import { ButtonContainer } from "components/Button/ActionButton/ActionButton.styled";
import { Highlight } from "components/Hightlight/Hightlight.style";

export const CareersBannerContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 152px 0 0 0;
  font-weight: 400;
  @media (max-width: 1170px) {
    padding: 112px 0 0;
    & {
      justify-content: center;
    }
    & ${ButtonContainer} {
      max-width: none;
    }
  }
`;
export const CareersBannerParagraph = styled(Box)`
  max-width: 517px;
`;
export const CareersBannerParagraphHeader = styled(Box)`
  font-size: 72px;
  font-stretch: expanded;
  letter-spacing: -1px;
  color: ${(props) => props?.theme.colors?.dark};

  @media (max-width: 1170px) {
    font-size: 28px;
    width: 200px;
  }
`;
export const CareersBannerParagraphText = styled(Box)`
  color: ${(props) => props?.theme.colors?.headerColor};
  & ${SubSectionContainerText} {
    margin: 12px 100px 34px 0;
  }

  & ${Highlight} {
    font-stretch: normal;
  }

  @media (max-width: 1170px) {
    & ${SubSectionContainerText} {
      font-size: 16px;
      line-height: 24px;
      margin: 10px 0;
      padding: 0 10px 0 0;
    }
    & ${Highlight} {
      line-height: 24px;
    }
  }
`;
export const CareersBannerImage = styled(Box)`
  @media (max-width: 1170px) {
    & ${ImageContainer}, & ${ImageElement}, & img {
      height: auto;
      width: 100vw;
      margin: 0 0 -2px 0;
      border-radius: 0;
    }
  }
`;
export const Bold = styled.span`
  font-weight: 800;
  font-stretch: expanded;
`;
