import React from "react";
import PropTypes from "prop-types";
import {
  CardContainer,
  CardDetails,
  CardTitle,
  CardImage,
} from "./Card.styled";

const Card = ({ title, image, children, bgColor = "white" }) => {
  return (
    <CardContainer elevation={5} $bgColor={bgColor}>
      <CardImage component="img" image={image} alt={title} />
      <CardDetails sx={{ width: "100%" }}>
        <CardTitle sx={{ fontSize: 18 }} gutterBottom>
          {title}
        </CardTitle>
        {children}
      </CardDetails>
    </CardContainer>
  );
};
Card.propTypes = {
  bgColor: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  image: PropTypes.string,
  children: PropTypes.node,
};
export default Card;
