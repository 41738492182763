import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { Input } from './CustomForm.style'
import upload from '../../assets/images/png/upload.png'
import { CustomFileInputContainer , FileInputLabel, FileName } from 'components/Footer/Footer.style';
const CustomFileInput = (props) => {
    const { name, accept, handleInputChange, isSubmited } = props;  
    const [fileName , setFileName] = useState('')
    const onUpload = () => {
        document.getElementById(name).click();
    }
    const onChange =() => {
        const inputField = document.getElementById(name);
        if(inputField !== undefined && inputField.files !== undefined && inputField.files[0] !== null && inputField.files.length > 0 && inputField.files[0] !== undefined)
        {
            setFileName(document.getElementById(name).files[0]?.name);
            handleInputChange(null, document.getElementById(name).files[0]?.name);
        }
        else {
            handleInputChange(null, '');
            inputField.files = [];
        }
    }
    useEffect(() => {
        setFileName('');
    }, [isSubmited])
    return (
        <CustomFileInputContainer onClick={onUpload}>
            <Input onChange={onChange} type="file" name={name} id={name} accept={accept} show={false} />
            <img width="60" src={upload} />
            <FileInputLabel >Click here to upload your CV</FileInputLabel>
            <FileName>{fileName}</FileName>
        </CustomFileInputContainer>
    );
}
CustomFileInput.propTypes = {
    name: PropTypes.string,
    accept: PropTypes.any,
    handleInputChange: PropTypes.func,
    isSubmited: PropTypes.bool
};
export default CustomFileInput;
