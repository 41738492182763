import styled, { css } from "styled-components";
import { Grid } from "@mui/material";
import { HighlightLinkContener } from "components/Hightlight/Hightlight.style";

export const SectionContainer = styled(Grid)`
  display: flex;
  padding: 0 8px;
  font-size: 24px;
  ${(props) =>
    !props?.$fullWidth &&
    css`
      padding: 0px calc((100% - 1170px) / 2);
    `}

  background-color: ${(props) => props?.theme.colors[props?.$bgColor]};

  color: ${(props) =>
    props?.$bgColor == "white" && props?.theme.colors?.thirdTextColor};

  & ${HighlightLinkContener} {
    font-stretch: ${(props) => props?.$bgColor == "white" && "normal"};
  }

  @media (max-width: 1024px) {
    padding: unset;
    font-size: 14px;
    padding: 0 14.5px;
  }
`;
export const TitleHighlight = styled.span`
  color: ${(props) =>
    props?.isStyled
      ? props?.theme.colors?.secondaryOpaque
      : props?.theme.colors?.dark};
  font-weight: 800;
`;
export const SectionTitle = styled.div`
  font-size: 24px;
  margin: ${(props) => props.margin};
  text-align: right;
  font-size: 86px;
  letter-spacing: -1px;
  font-stretch: expanded;
  font-weight: 800;
  padding-bottom: ${(props) => props.paddingBottom};
  line-height: ${(props) => props?.lineHeight};
  color: ${(props) => props?.theme.colors[props?.$color]};
  ${(props) =>
    props?.$alignLeft &&
    css`
      text-align: left;
    `}
  border-bottom: 1px solid
    ${(props) => props?.theme.colors[props?.$borderColor]};

  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 20px;
  }

  &:has(${TitleHighlight}) {
    font-weight: 300;
  }
`;
