import { Box } from "@mui/material";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import { Link as ScrollLink } from "react-scroll";

export const HeaderNavigationItemIconContainer = styled(Box)``;
export const HeaderNavigationItemIconTitle = styled(Box)`
  font-size: 15px;
  //line-height: 20px;
  font-weight: 300;
  font-stretch: expanded;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 4px 0;
  color: ${(props) => props?.theme.colors.textColor};

  ${(props) =>
    props?.$isActive &&
    css`
      border-bottom: 1px solid ${(props) => props?.theme.colors.secondaryOpaque};
      color: ${(props) => props?.theme.colors.secondaryOpaque};
    `}

  ${(props) =>
    props?.$highlightTitle &&
    css`
      color: ${(props) => props?.theme.colors.secondaryOpaque};
    `}
`;

export const HeaderNavigationItemContainer = styled(NavLink)`
  display: flex;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  width: fit-content;
  padding: 16px 20px;
  cursor: pointer;
  height: 100%;
  transition-duration: 0.1s;

  &[aria-checked="true"] ${HeaderNavigationItemIconTitle} {
    font-weight: 700;
  }
`;
export const HeaderNavigationItemLinkContainer = styled(ScrollLink)`
  display: flex;
  justify-content: center;
  text-decoration: none;
  align-items: center;
  width: fit-content;
  padding: 20px;
  cursor: pointer;
  height: 100%;
  transition-duration: 0.1s;

  &[aria-checked="true"] ${HeaderNavigationItemIconTitle} {
    font-weight: 700;
  }
`;
