import React from "react";
import {
  HomeAgileProcessContainer,
  HomeAgileProcessParagraph1,
  HomeAgileProcessParagraph,
  HomeAgileProcessParagraph2,
  HomeAgileProcessParagraphText,
  HomeAgileProcessCard,
  HomeAgileProcessCardImage,
  HomeAgileProcessCardContract,
  HomeAgileProcessCardHeader,
  HomeAgileProcessCardText,
} from "./HomeAgileProcess.styled";
import { Trans, useTranslation } from "react-i18next";
import useDevice from "hooks/useDevice";
import { ReactComponent as AgileProcess } from "assets/images/pages/home/agile-process.svg";
import { ReactComponent as AgileProcessIcon } from "assets/images/pages/home/agile-process-icon.svg";

const HomeAgileProcess = () => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useDevice();

  const agileProcess = [
    {
      header: "homePage.paragraf4.items.item1.header",
      text: "homePage.paragraf4.items.item1.text",
    },
    {
      header: "homePage.paragraf4.items.item2.header",
      text: "homePage.paragraf4.items.item2.text",
    },
    {
      header: "homePage.paragraf4.items.item3.header",
      text: "homePage.paragraf4.items.item3.text",
    },
    {
      header: "homePage.paragraf4.items.item4.header",
      text: "homePage.paragraf4.items.item4.text",
    },
    {
      header: "homePage.paragraf4.items.item5.header",
      text: "homePage.paragraf4.items.item5.text",
    },
  ];

  return (
    <HomeAgileProcessContainer>
      <HomeAgileProcessParagraph1>
        <HomeAgileProcessParagraph>
          <AgileProcess
            width={isMobile || isTablet ? "204px" : "370px"}
            height={isMobile || isTablet ? "184px" : "317px"}
          />
          <HomeAgileProcessParagraphText>
            <Trans i18nKey="homePage.paragraf4.text" />
          </HomeAgileProcessParagraphText>
        </HomeAgileProcessParagraph>
      </HomeAgileProcessParagraph1>
      <HomeAgileProcessParagraph2>
        {agileProcess?.map((card, index) => {
          return (
            <HomeAgileProcessCard key={index}>
              <HomeAgileProcessCardImage>
                <AgileProcessIcon
                  width={isMobile || isTablet ? "20px" : "50px"}
                  height={isMobile || isTablet ? "20px" : "50px"}
                />
              </HomeAgileProcessCardImage>
              <HomeAgileProcessCardContract>
                <HomeAgileProcessCardHeader>
                  {t(card.header)}
                </HomeAgileProcessCardHeader>
                <HomeAgileProcessCardText>
                  {t(card.text)}
                </HomeAgileProcessCardText>
              </HomeAgileProcessCardContract>
            </HomeAgileProcessCard>
          );
        })}
      </HomeAgileProcessParagraph2>
    </HomeAgileProcessContainer>
  );
};

export default HomeAgileProcess;
