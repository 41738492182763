import React, { useState } from "react";
import PropTypes from "prop-types";
import MenuIcon from "@mui/icons-material/Menu";
import { HeaderIconContainer } from "../Header.styled";
import { Drawer } from "@mui/material";
import { headerNavigationConstants } from "constants/navigationConstants";
import HeaderNavigationItem from "../HeaderNavigation/HeaderNavigationItem/HeaderNavigationItem";
import { DrawerMenu } from "./HeaderDrawer.styled";

// In progress
const HeaderDrawer = () => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <>
      <HeaderIconContainer
        onClick={() => setIsOpened((prevState) => !prevState)}
      >
        <MenuIcon />
      </HeaderIconContainer>
      <Drawer
        anchor="top"
        sx={{ pt: 5 }}
        open={isOpened}
        onOpen={() => setIsOpened(true)}
        onClose={() => setIsOpened(false)}
      >
        <DrawerMenu>
          {headerNavigationConstants.map?.((singleNavigationItem, index) => (
            <HeaderNavigationItem key={index} route={singleNavigationItem} />
          ))}
        </DrawerMenu>
      </Drawer>
    </>
  );
};

HeaderDrawer.propTypes = {
  children: PropTypes.node,
};

export default HeaderDrawer;
