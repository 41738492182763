import React from "react";
import Image from "../Image/Image";
import { PropTypes } from "prop-types";
import SubSectionText from "components/SubSectionText/SubSectionText";
import { InnerSubSection, SubSectionContainer } from "./SubSection.styled";

const SubSection = (props) => {
  const { height, marginTop } = props;
  return (
    <SubSectionContainer
      marginTop={marginTop}
      container
      reverse={props.reverse}
    >
      <InnerSubSection
        height={height !== undefined && height !== "" ? height : "100%"}
      >
        {props.reverse ? (
          <Image
            image={props.image}
            alt=""
            height={height !== undefined && height !== "" ? height : "100%"}
          />
        ) : (
          <SubSectionText
            text={props.text}
            textColor={props?.textColor}
            HighlightColor={props?.HighlightColor}
            applyPadding={props.applyPadding}
            IsBold={props.IsBold}
          />
        )}
      </InnerSubSection>
      <InnerSubSection
        item
        reverse={props.reverse}
        height={height !== undefined && height !== "" ? height : "100%"}
      >
        {props.reverse ? (
          <SubSectionText
            text={props.text}
            textColor={props?.textColor}
            HighlightColor={props?.HighlightColor}
            applyPadding={props.applyPadding}
            IsBold={props.IsBold}
          />
        ) : (
          <Image
            image={props.image}
            alt=""
            height={height !== undefined && height !== "" ? height : "100%"}
          />
        )}
      </InnerSubSection>
    </SubSectionContainer>
  );
};
SubSection.propTypes = {
  text: PropTypes.any,
  image: PropTypes.any,
  reverse: PropTypes.any,
  textColor: PropTypes.string,
  HighlightColor: PropTypes.string,
  applyPadding: PropTypes.bool,
  IsBold: PropTypes.bool,
  height: PropTypes.string,
  marginTop: PropTypes.string,
};
SubSection.defaultProps = {
  reverse: false,
  textColor: "white",
  HighlightColor: "thertiary",
};
export default SubSection;
