import React from "react";
import {
  CommitmentImageContainer,
  CommitmentImage,
} from "components/Image/Image.styled";
import PropTypes from "prop-types";
import CommitmentText from "./CommitmentText";
import {
  CommitmentContainer,
  CommitmentInnerContainer,
} from "components/Commitment/Commitment.style";

const Commitment = (props) => {
  return (
    <CommitmentContainer container type={props.type}>
      {props.reverse ? (
        <CommitmentInnerContainer reverse={props.reverse}>
          <CommitmentText
            iconProperties={props.iconProperties}
            title={props.title}
            text={props.text}
            reverse={props.reverse}
            icon={props.icon}
          />
          <CommitmentImageContainer item>
            <CommitmentImage src={props.image} reverse={props.reverse} />
          </CommitmentImageContainer>
        </CommitmentInnerContainer>
      ) : (
        <CommitmentInnerContainer reverse={props.reverse}>
          <CommitmentImageContainer item>
            <CommitmentImage src={props.image} reverse={props.reverse} />
          </CommitmentImageContainer>
          <CommitmentText
            iconProperties={props.iconProperties}
            title={props.title}
            text={props.text}
            reverse={props.reverse}
            icon={props.icon}
          />
        </CommitmentInnerContainer>
      )}
    </CommitmentContainer>
  );
};
Commitment.propTypes = {
  image: PropTypes.any,
  title: PropTypes.any,
  text: PropTypes.any,
  reverse: PropTypes.bool,
  type: PropTypes.any,
  icon: PropTypes.any,
  iconProperties: PropTypes.any,
};
export default Commitment;
