import { authScopeStringGetHelper } from "util/authScopeHelpers";
import primary from "./primaryTheme/primaryTheme";
import secondary from "./secondaryTheme/secondaryTheme";
import { LIGHT_THEME } from "constants/themeConstants";

export let getTheme = () => {
  let selectedThemeStorage = authScopeStringGetHelper("colorMode");

  if (
    selectedThemeStorage == undefined ||
    selectedThemeStorage === LIGHT_THEME
  ) {
    return { ...primary };
  }
  return { ...secondary };
};

let selectedTheme = getTheme();

export default selectedTheme;
