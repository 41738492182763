import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  ScrollingBannerContener,
  ScrollingBannerScroll,
  ScrollingBannerItem,
  ScrollingBannerFade,
} from "./ScrollingBanner.styled";
import useDevice from "hooks/useDevice";

const ScrollingBanner = (props) => {
  const { isMobile, isTablet } = useDevice();
  const [initAnimation, setInitAnimation] = useState(true);
  const scrollingBannerRef = useRef(null);

  useEffect(() => {
    const handleAnimationEnd = () => {
      setInitAnimation(false);
    };

    scrollingBannerRef?.current?.addEventListener(
      "animationend",
      handleAnimationEnd
    );

    return () => {
      scrollingBannerRef?.current?.removeEventListener(
        "animationend",
        handleAnimationEnd
      );
    };
  }, []);

  return (
    <ScrollingBannerContener>
      <ScrollingBannerScroll
        ref={scrollingBannerRef}
        $scrollTwo={false}
        $width={props.width}
        initAnimation={initAnimation}
      >
        {props?.images &&
          props.images.map((item, index) => (
            <ScrollingBannerItem
              key={index}
              src={item}
              height={isMobile || isTablet ? "60px" : "120px"}
              width={isMobile || isTablet ? "120px" : "200px"}
            ></ScrollingBannerItem>
          ))}
      </ScrollingBannerScroll>
      <ScrollingBannerScroll $scrollTwo $width={props.width}>
        {props?.images &&
          props.images.map((item, index) => (
            <ScrollingBannerItem
              key={index}
              src={item}
              height={isMobile || isTablet ? "60px" : "120px"}
              width={isMobile || isTablet ? "120px" : "200px"}
            ></ScrollingBannerItem>
          ))}
      </ScrollingBannerScroll>
      <ScrollingBannerFade $bgColor={props.bgColor}></ScrollingBannerFade>
    </ScrollingBannerContener>
  );
};

ScrollingBanner.propTypes = {
  children: PropTypes.node,
  items: PropTypes.object,
  images: PropTypes.array,
  width: PropTypes.string,
  bgColor: PropTypes.string,
};

ScrollingBanner.defaultProps = {
  width: "1500px",
};

export default ScrollingBanner;
