import { Box } from "@mui/material";
import styled from "styled-components";
import AgileProcess from "assets/images/pages/home/agile-process.png";

export const HomeAgileProcessContainer = styled(Box)`
  gap: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 120px;
  justify-content: center;

  @media (max-width: 1170px) {
    gap: 32px;
    margin-bottom: 64px;
  }
`;

export const HomeAgileProcessParagraph1 = styled(Box)`
  max-width: 370px;
  gap: 10px;

  @media (max-width: 1170px) {
    gap: 32px;
  }
`;

export const HomeAgileProcessParagraph = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media (max-width: 1170px) {
    gap: 32px;
  }

  @media (max-width: 768px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const HomeAgileProcessParagraphText = styled(Box)`
  font-size: 20px;
  font-style: italic;
  font-weight: 300;
  line-height: 30px;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const HomeAgileProcessParagraph2 = styled(Box)`
  max-width: 784px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const HomeAgileProcessCardImage = styled(Box)`
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  width: 115px;
  background-image: url(${AgileProcess});
  background-repeat: no-repeat;

  height: 100%;

  padding-top: 21px;
  padding-left: 17.5px;

  @media (max-width: 768px) {
    width: 56px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-size: contain;

    padding-top: 8px;
    padding-left: 5px;
  }
`;

export const HomeAgileProcessCardContract = styled(Box)`
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;

  grid-row-gap: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 250px;
  padding: 16px;
  text-decoration: none;
  display: flex;

  @media (max-width: 768px) {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    height: 96px;
    gap: 10px;
  }
`;

export const HomeAgileProcessCardHeader = styled(Box)`
  font-weight: 700;
  font-size: 32px;
  line-height: 30.87px;
  font-stretch: expanded;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 12px;
  }
`;

export const HomeAgileProcessCardText = styled(Box)`
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 12px;
  }
`;

export const HomeAgileProcessCard = styled(Box)`
  display: flex;
  gap: 32px;
  align-items: stretch;
  flex-wrap: nowrap;
  border-radius: 16px;
  background-color: ${(props) => props?.theme.colors.thertiary};
  color: ${(props) => props?.theme.colors?.dark};

  &:first-child {
    background-color: ${(props) => props?.theme.colors.dark};
    color: ${(props) => props?.theme.colors.white};
  }

  &:last-child {
    background-color: ${(props) => props?.theme.colors.secondaryOpaque};
    color: ${(props) => props?.theme.colors.white};
  }

  @media (max-width: 768px) {
    gap: 0;
    border-radius: 8px;
  }
`;
