import React from "react";
import {
  ServiceLineUpContainer,
  ServiceLineInnerContainer,
  ServiceLineUpImageParagraphContainer,
  ServiceLineUpImageParagraph,
  ServiceLineUpParagraph,
  ServiceLineUpParagraphHeader,
  ServiceLineUpParagraphHeaderAlt,
  ServiceLineUpImageContainer,
  ServiceLineUpImage,
  Bold,
  ServicesLineUpTextContainer,
  ServicesLineUpText,
  CircleContainer,
  Circle,
  ServiceLineUpParagraphHeaderDevops,
  //mobile
  ServiceLineUpImageContainerMobile,
  ServiceLineUpImageMobile,
  ServicesLineUpTextMobile,
  ServiceLineInnerMobileContainer,
} from "./ServiceLineUp.style";
import { Trans } from "react-i18next";
import software_development_outsourcing from "assets/images/jpg/software_development_outsourcing.jpg";
import software_and_testing from "assets/images/jpg/software_and_testing.jpg";
import web_development from "assets/images/jpg/web_development.jpg";
import web_development_mob from "assets/images/jpg/web_development_mob.jpg";
import mobile_development from "assets/images/jpg/mobile_development.jpg";
import devops from "assets/images/jpg/devops.jpg";
import it_consulting from "assets/images/jpg/consult_800.jpg";
import it_project_managment from "assets/images/jpg/it_project_managment.jpg";
import ai_development from "assets/images/jpg/ai_development.jpg";
import powerbi from "assets/images/jpg/powerbi.jpg";
import azure_data_factory from "assets/images/jpg/azure_data_factory.jpg";
import { Highlight } from "components/Hightlight/Hightlight.style";
import { ReactComponent as ButtonIcon } from "assets/images/svg/arrow-up-right.svg";
import useDevice from "hooks/useDevice";
const ServiceLineUp = () => {
  const { isMobile, isTablet } = useDevice();
  const services = [
    {
      header: "homePage.paragraf1.items.item1.header",
      image: software_development_outsourcing,
      position: "right",
      text: "homePage.paragraf1.items.item1.text",
      radius: true,
      marginTop: "260px",
      imgWidthMobile: "240px",
      topMobile: "-30px",
      width: "440.5px",
    },
    {
      header: "homePage.paragraf1.items.item2.header",
      image: software_and_testing,
      position: "right",
      text: "homePage.paragraf1.items.item2.text",
      radius: true,
      imgWidthMobile: "130px",
      marginTop: "96px",
      width: "280.5px",
    },
    {
      header: "homePage.paragraf1.items.item3.header",
      image: isMobile || isTablet ? web_development_mob : web_development,
      position: "center",
      text: "homePage.paragraf1.items.item3.text",
      radius: true,
      imgWidthMobile: "190px",
      marginTop: "96px",
      width: "410.5px",
    },
    {
      header: "homePage.paragraf1.items.item4.header",
      image: mobile_development,
      position: "right",
      text: "homePage.paragraf1.items.item4.text",
      radius: true,
      imgWidthMobile: "190px",
      marginTop: "96px",
      width: "340.5px",
    },
    {
      header: "homePage.paragraf1.items.item5.header",
      image: devops,
      position: "right",
      text: "homePage.paragraf1.items.item5.text",
      radius: true,
      imgWidthMobile: "180px",
      topMobile: "5px",
      width: "280.5px",
    },
    {
      header: "homePage.paragraf1.items.item6.header",
      image: it_consulting,
      position: "right",
      text: "homePage.paragraf1.items.item6.text",
      radius: true,
      imgWidthMobile: "200px",
      //topMobile: "-35px",
      marginTop: "260px",
      width: "400.5px",
    },
    {
      header: "homePage.paragraf1.items.item7.header",
      image: it_project_managment,
      position: "right",
      text: "homePage.paragraf1.items.item7.text",
      radius: true,
      imgWidthMobile: "220px",
      marginTop: "260px",
      topMobile: "-35px",
      width: "440.5px",
    },
    {
      header: "homePage.paragraf1.items.item8.header",
      image: ai_development,
      position: "right",
      text: "homePage.paragraf1.items.item8.text",
      radius: true,
      marginTop: "75px",
      width: "410.5px",
      imgWidthMobile: "190px",
    },
    {
      header: "homePage.paragraf1.items.item9.header",
      image: powerbi,
      position: "right",
      text: "homePage.paragraf1.items.item9.text",
      radius: true,
      imgWidthMobile: "150px",
      width: "280.5px",
    },
    {
      header: "homePage.paragraf1.items.item10.header",
      image: azure_data_factory,
      position: "right",
      text: "homePage.paragraf1.items.item10.text",
      radius: true,
      marginTop: "260px",
      imgWidthMobile: "174px",
      width: "360.5px",
    },
  ];
  const devOpsIndex = 4;
  return (
    <ServiceLineUpContainer>
      {isMobile || isTablet
        ? services?.map((service, index) => {
            return (
              <ServiceLineInnerMobileContainer key={index}>
                <ServiceLineInnerContainer isMobile={isMobile}>
                  <ServiceLineUpImageParagraph>
                    <ServiceLineUpParagraph>
                      {index == devOpsIndex ? (
                        <Trans
                          i18nKey={service.header}
                          components={{
                            2: (
                              <ServiceLineUpParagraphHeaderDevops
                                radius={service.radius}
                                imgWidth={service.imgWidthMobile}
                              />
                            ),
                            1: (
                              <ServiceLineUpParagraphHeader
                                radius={service.radius}
                                imgWidth={service.imgWidthMobile}
                              />
                            ),
                            0: <Bold />,
                          }}
                        />
                      ) : (
                        <Trans
                          i18nKey={service.header}
                          components={{
                            2: (
                              <ServiceLineUpParagraphHeaderAlt
                                radius={service.radius}
                                imgWidth={service.imgWidthMobile}
                              />
                            ),
                            1: (
                              <ServiceLineUpParagraphHeader
                                radius={service.radius}
                                imgWidth={service.imgWidthMobile}
                              />
                            ),
                            0: <Bold />,
                          }}
                        />
                      )}
                    </ServiceLineUpParagraph>
                    <ServiceLineUpImageContainerMobile>
                      <ServiceLineUpImageMobile
                        src={service.image}
                        imgWidthMobile={service.imgWidthMobile}
                        rightMobile={service.rightMobile}
                        topMobile={service.topMobile}
                      ></ServiceLineUpImageMobile>
                    </ServiceLineUpImageContainerMobile>
                  </ServiceLineUpImageParagraph>
                </ServiceLineInnerContainer>
                <ServicesLineUpTextContainer>
                  <ServicesLineUpTextMobile>
                    <Trans
                      i18nKey={service.text}
                      components={[
                        <Highlight key={0} isText={true} color="thertiary" />,
                      ]}
                    />
                  </ServicesLineUpTextMobile>
                </ServicesLineUpTextContainer>
                <CircleContainer>
                  <Circle>
                    <ButtonIcon />
                  </Circle>
                </CircleContainer>
              </ServiceLineInnerMobileContainer>
            );
          })
        : services?.map((service, index) => {
            return (
              <ServiceLineInnerContainer key={index} isMobile={isMobile}>
                <ServiceLineUpImageParagraphContainer>
                  <ServiceLineUpImageParagraph>
                    <ServiceLineUpParagraph>
                      {index == devOpsIndex ? (
                        <Trans
                          i18nKey={service.header}
                          components={{
                            2: (
                              <ServiceLineUpParagraphHeaderDevops
                                radius={service.radius}
                                imgWidth={service.width}
                              />
                            ),
                            1: (
                              <ServiceLineUpParagraphHeader
                                radius={service.radius}
                                imgWidth={service.width}
                              />
                            ),
                            0: <Bold />,
                          }}
                        />
                      ) : (
                        <Trans
                          i18nKey={service.header}
                          components={{
                            2: (
                              <ServiceLineUpParagraphHeaderAlt
                                radius={service.radius}
                                imgWidth={service.width}
                              />
                            ),
                            1: (
                              <ServiceLineUpParagraphHeader
                                radius={service.radius}
                                imgWidth={service.width}
                              />
                            ),
                            0: <Bold />,
                          }}
                        />
                      )}
                    </ServiceLineUpParagraph>
                    <ServiceLineUpImageContainer left={index % 2 == 0}>
                      <ServiceLineUpImage
                        src={service.image}
                        imgWidth={service.width || "100%"}
                        position={service.position}
                      />
                    </ServiceLineUpImageContainer>
                  </ServiceLineUpImageParagraph>
                </ServiceLineUpImageParagraphContainer>
                <ServicesLineUpTextContainer marginTop={service.marginTop}>
                  <ServicesLineUpText>
                    <Trans
                      i18nKey={service.text}
                      components={[
                        <Highlight key={0} isText IsBold color="thertiary" />,
                      ]}
                    />
                  </ServicesLineUpText>
                  <CircleContainer>
                    <Circle>
                      <ButtonIcon />
                    </Circle>
                  </CircleContainer>
                </ServicesLineUpTextContainer>
              </ServiceLineInnerContainer>
            );
          })}
    </ServiceLineUpContainer>
  );
};
export default ServiceLineUp;
