import React from "react";
import { PropTypes } from "prop-types";
import { SubSectionContainerText } from "../SubSection/SubSection.styled";
import { Trans, useTranslation } from "react-i18next";
import { Highlight } from "../Hightlight/Hightlight.style";
const SubSectionText = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <SubSectionContainerText textAlign="left" $textColor={props.textColor}>
        <Trans
          t={t}
          i18nKey={props.text}
          components={[
            <Highlight key={0} isText={true} color={props?.HighlightColor} applyPadding={props.applyPadding} IsBold={props.IsBold} />,
            <Highlight key={1} isText={true} color={props?.HighlightColor} applyPadding={props.applyPadding} IsBold={props.IsBold}/>,
          ]}
        />
      </SubSectionContainerText>
    </>
  );
};
SubSectionText.propTypes = {
  text: PropTypes.any,
  textColor: PropTypes.string,
  HighlightColor: PropTypes.string,
  applyPadding: PropTypes.bool,
  IsBold: PropTypes.bool
};

SubSectionText.defaultProps = {
  textColor: "white",
  HighlightColor: "thertiary",
};

export default SubSectionText;
