import React from "react";
import PropTypes from "prop-types";
import { ImageContainer, ImageElement } from "./Image.styled";

const Image = (props) => {
  return (
    <ImageContainer>
      <ImageElement src={props.image} height={props.height} />
    </ImageContainer>
  );
};
Image.propTypes = {
  alt: PropTypes.string,
  image: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Image.defaultProps = {
  height: "400px",
};
export default Image;
