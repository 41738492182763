import React from "react";
import Commitment1 from "../../static/commitment1.jpg";
import Commitment2 from "../../static/commitment2.jpg";
import Commitment3 from "../../static/commitment3.jpg";
import Commitment4 from "../../static/commitment4.jpg";
import CommitmentIcon1 from "../../static/commitment_icon1.svg";
import CommitmentIcon2 from "../../static/commitment_icon2.png";
import CommitmentIcon3 from "../../static/commitment_icon3.png";
import CommitmentIcon4 from "../../static/commitment_icon4.png";
import i18next from "i18next";
import Commitment from "components/Commitment/Commitment";
import { BackgroundType } from "../../constants/backgroundType";
import { Trans, useTranslation } from "react-i18next";
//import useDevice from "hooks/useDevice";
const Commitments = () => {
  //const { isMobile, isTablet } = useDevice();
  //const isSmallerDesktop = window.innerWidth < 1200;
  const breakElement = <br key={0} />;

  const { t } = useTranslation();
  const commitments = [
    {
      title: (
        <Trans
          t={t}
          i18nKey="aboutPage.commitment1Title"
          components={[breakElement]}
        />
      ),
      text: i18next.t("aboutPage.commitment1"),
      image: Commitment1,
      type: BackgroundType.WHITE,
      reverse: true,
      icon: CommitmentIcon1,
      iconProperties: {
        height: "138px",
        width: "50px",
        right: "-41%",
      },
    },
    {
      title: (
        <Trans
          t={t}
          i18nKey="aboutPage.commitment2Title"
          components={[breakElement]}
        />
      ),
      text: i18next.t("aboutPage.commitment2"),
      image: Commitment2,
      type: BackgroundType.YELLOW,
      icon: CommitmentIcon2,
      iconProperties: {
        height: "50px",
        width: "45px",
        left: "-43%",
      },
    },
    {
      title: i18next.t("aboutPage.commitment3Title"),
      text: i18next.t("aboutPage.commitment3"),
      image: Commitment3,
      type: BackgroundType.YELLOW,
      icon: CommitmentIcon3,
      reverse: true,
      iconProperties: {
        height: "45px",
        width: "35px",
        right: "-17%",
      },
    },
    {
      title: (
        <Trans
          t={t}
          i18nKey="aboutPage.commitment4Title"
          components={[breakElement]}
        />
      ),
      text: i18next.t("aboutPage.commitment4"),
      image: Commitment4,
      type: BackgroundType.BLUE,
      icon: CommitmentIcon4,
      iconProperties: {
        height: "45px",
        width: "35px",
        left: "-29%",
        top: "16%",
      },
    },
  ];
  return (
    <>
      {commitments?.map((commitment, index) => {
        return (
          <Commitment
            key={index}
            type={commitment.type}
            image={commitment.image}
            title={commitment.title}
            text={commitment.text}
            reverse={commitment.reverse}
            icon={commitment.icon}
            iconProperties={commitment.iconProperties}
          />
        );
      })}
    </>
  );
};
export default Commitments;
