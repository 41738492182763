import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { HeaderContainer, HeaderSideContainer } from "./Header.styled";
import HeaderNavigation from "./HeaderNavigation/HeaderNavigation";
import Logo from "components/Logo/Logo";
import { PAGES } from "constants/pages";
import useDevice from "hooks/useDevice";
import HeaderDrawer from "./HeaderDrawer/HeaderDrawer";

const Header = () => {
  const [hideHeader, setHideHeader] = useState(false);
  const [offsetY, setOffsetY] = useState(window.scrollY);

  useEffect(() => {
    const scroll = () => {
      setOffsetY((privState) => {
        if (window.scrollY > 15 && privState < window.scrollY) {
          setHideHeader(true);
        } else {
          setHideHeader(false);
        }
        return window.scrollY;
      });
    };

    window.addEventListener("scroll", scroll);
    return () => window.removeEventListener("scroll", scroll);
  }, [offsetY]);

  const { isMobile } = useDevice();
  return (
    <HeaderContainer $hide={hideHeader} $scrollY={window.scrollY}>
      <HeaderSideContainer>
        <Logo as={NavLink} to={PAGES.BASE.route} />
        {isMobile && <HeaderDrawer />}
      </HeaderSideContainer>
      {!isMobile && (
        <HeaderSideContainer>
          <HeaderNavigation />
        </HeaderSideContainer>
      )}
    </HeaderContainer>
  );
};

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
