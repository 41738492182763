import styled, { css } from "styled-components";
import Box from "@mui/material/Box";
import { Ul, Li } from "../../components/Ul/Ul.styled";
import { Highlight } from "../../components/Hightlight/Hightlight.style";
import { Link } from "@mui/material";
import { FormContainer } from "../../components/CustomForm/CustomForm.style";
import { ButtonContainer } from "../../components/Button/ActionButton/ActionButton.styled";
import { LogoImage } from "../../components/Logo/Logo.styled";
import { Tabs, Tab } from "@mui/material";
export const FooterContainer = styled.footer`
  background-color: ${(props) => props?.theme?.colors?.dark};
  padding-top: 112px;
  width: 100%;
  @media (max-width: 768px) {
    padding-top: 64px;
  }
`;
export const TabsContainer = styled(Tabs)`
  & button {
    text-transform: unset !important;
    -webkit-transition: all 500ms ease-in;
    -moz-transition: all 500ms ease-in;
    -ms-transition: all 500ms ease-in;
    -o-transition: all 500ms ease-in;
    transition: all 500ms ease-in;
  }
  & .Mui-selected {
    background-color: ${(props) =>
      props?.theme?.colors?.secondaryOpaque}!important;
    span {
      color: ${(props) => props?.theme?.colors?.textColor};
    }
  }
  & button:not(.Mui-selected) {
    border-bottom: 2px solid ${(props) => props?.theme?.colors?.secondaryOpaque} !important;
  }

  @media (max-width: 768px) {
    & button:nth-child(1).Mui-selected {
      border-top-right-radius: 20px;
    }
    & button:nth-child(2).Mui-selected {
      border-top-left-radius: 20px;
    }
    & button:not(.Mui-selected) {
      border-bottom: none !important;
    }
  }
`;
export const TabContainer = styled(Tab)`
  width: ${(props) => props.width};
  max-width: ${(props) => props.width};
  border-bottom: 2px solid ${(props) => props?.theme?.colors?.secondaryOpaque};
  float: left;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
  text-align: center;
  color: ${(props) => props?.theme?.colors?.secondTextColor}!important;
  font-size: 42px;
  font-weight: 400;
  line-height: 64px;
  text-decoration: none;
  letter-spacing: -1px;
  display: block;
  @media (max-width: 1200px) {
    &:nth-child(1) {
      max-width: 40%;
    }
    &:nth-child(2) {
      max-width: 60%;
    }
  }
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 64px;
  }
  @media (max-width: 432px) {
    line-height: 22px;
  }
  & ${Highlight} {
    font-weight: 800;
  }
  & span {
    display: inline-block;
  }
`;
export const CustomTabPanelContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  padding: 0px;
  margin-top: 64px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    & ${FormContainer} {
      @media (max-width: 768px) {
        border-radius: 0px;
      }
    }
  }
`;
export const Reason = styled.span`
  color: #f8f8f8;
  margin-top: 0;
  margin-bottom: -10px;
  font-size: 32px;
  font-weight: 700;
  line-height: 125%;
  text-decoration: none;
  padding-left: 50px;
  display: inline-block;
  vertical-align: top;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;
export const CustomTabPanelInnerContainer = styled.div`
  width: 50%;
  & ${ButtonContainer} {
    padding: 38px 44px;
    font-size: 24px;
  }
  & ${Ul} {
    padding-left: 32px;
  }
  & ${Li} {
    margin-bottom: 40px;
    display: table;
  }
  @media (max-width: 768px) {
    width: 100%;
    &:first-of-type {
      margin-bottom: 32px;
    }
    & ${Li} {
      margin-bottom: 40px;
      display: table;
      padding-top: 0px;
    }
    & ${Reason} {
      margin-top: 5px;
    }
  }
`;
export const CustomFileInputContainer = styled.div`
  padding: 16.5px 20px;
  border: 2px ${(props) => props.theme.colors.dark} dashed;
  border-radius: 24px;
  text-align: center;
  cursor: pointer;
`;
export const FileInputLabel = styled.span`
  font-size: 14px;
  line-height: 20px;
  display: block;
  color: ${(props) => props?.theme.colors.thirdTextColor};
  font-weight: 700;
`;
export const FileName = styled.div`
  font-size: 14px;
  color: ${(props) => props?.theme.colors.thirdTextColor};
`;
export const ReasonIcon = styled.img`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 32px;
  @media (max-width: 768px) {
    width: 26px;
  }
`;

export const FooterSection = styled.div`
  width: 100%;
  ${(props) =>
    props?.textAlign &&
    css`
      text-align: ${(props) => props.textAlign};
    `}
  @media (max-width: 768px) {
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 15px !important;
    font-weight: 400;
    line-height: 20px;
    padding: 12px;
    & ${LogoImage} {
      max-height: 30px;
    }
    @media (max-width: 768px) {
      padding-bottom: 9px;
      margin-top: 12px;
    }
  }
`;
export const FooterBottomContainer = styled.div`
  display: flex !important;
  flex-direction: row !important;
  border-top: 2px solid ${(props) => props.theme?.colors.secondaryOpaque};
  padding-top: 20px;
  margin-top: 143px;
  @media (max-width: 768px) {
    flex-direction: column !important;
    border-top: 1px solid #2d3042;
    margin-top: 66px;
    & ${FooterSection}:nth-child(1) {
      padding-bottom: 0px;
    }
  }
`;
export const FooterDevider = styled.div`
  padding: 20px 15%;
`;
export const DividerLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme?.colors.secondaryOpaque};
`;
export const FooterMenu = styled.div`
  display: flex;
  direction: row;
  color: ${(props) => props.theme.colors.secondTextColor};
  ${(props) =>
    props?.isCentered &&
    css`
      justify-content: center;
    `}
  a {
    padding: 12px;
    font-size: 14px;
  }
  a > div {
    color: ${(props) => props.theme.colors.secondTextColor};
    height: unset;
    text-decoration: none;
  }
`;
export const SocialNetworkContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px;
`;
export const SocialNetwork = styled(Link)`
  object-fit: cover;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  text-decoration: none;
  display: flex;
  margin: 0px 20px;
  @media (max-width: 768px) {
    & img {
      border-radius: 0;
      width: auto;
      height: 20px;
    }
  }
`;
export const FooterCopyRight = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 101px;
  @media (max-width: 768px) {
    margin-top: 23px;
  }
`;
export const CopyRightLink = styled(Link)`
  color: ${(props) => props.theme.colors.secondTextColor};
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    margin-bottom: 9px;
  }
`;
export const CopyRightText = styled.p`
  color: ${(props) => props.theme.colors.secondTextColor};
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  text-decoration: none;
  margin-bottom: 46px;
  @media (max-width: 768px) {
    margin-bottom: 37px;
  }
`;
