import styled, { css } from "styled-components";
import { handleColorType } from "../../util/helpers/handleColorType";
export const CommitmentTextInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 10px 40px 0 20px;
  @media (max-width: 1024px) {
    padding: 14px 0 0;
  }
`;
export const CommitmentContainer = styled.div`
  background-color: ${(props) =>
    handleColorType(props.type, props?.theme?.colors)};
  border-radius: 16px;
  margin: 32px 0px;

  &:last-of-type {
    margin-bottom: 152px;
  }
  @media (max-width: 1200px) {
    &:first-of-type {
      margin-top: 27px !important;
    }
    width: 50%;
    margin: 30px 25%;
    display: flex;
    flex-direction: ${(props) =>
      !props.reverse ? "column" : "column-reverse"};
  }
  @media (max-width: 768px) {
    width: 60%;
    min-height: 320px;
    // margin:30px 20%;
    &:first-of-type {
      margin-top: 27px !important;
    }
    &:last-of-type {
      margin-bottom: 66px !important;
    }
  }
  @media (max-width: 580px) {
    width: 76%;
    margin: 30px 12%;
    &:first-of-type {
      margin-top: 27px !important;
    }
  }
  @media (max-width: 444px) {
    width: 76%;
    margin: 30px 12%;
    &:first-of-type {
      margin-top: 27px !important;
    }
  }
  @media (max-width: 400px) {
    width: 76%;
    margin: 30px 12%;
    &:first-of-type {
      margin-top: 27px !important;
    }
  }
`;
export const CommitmentInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 320px;
  @media (max-width: 1200px) {
    flex-direction: ${(props) =>
      !props.reverse ? "column" : "column-reverse"};
    height: unset;
    width: 100%;
    height: auto;
  }
`;
export const CommitmentTextContainer = styled.div`
  line-height: 30px;
  color: ${(props) => props?.theme?.colors?.dark};
  text-align: ${(props) => (props.reverse ? "left" : "right")};

  font-size: 24px;
  margin-bottom: 10px;

  @media (max-width: 1200px) {
    font-size: 14px;
    text-align: left;
    padding: 0 15px 0 20px;
    line-height: 20px;
    margin-top: 0px;
  }
  @media (max-width: 768px) {
    line-height: 20px;
    margin-top: 0px;
    text-align: left;
  }
`;
export const CommitmentIcon = styled.img`
  //position: absolute;
  margin: 5px;
  vertical-align: middle;
  height: auto;

  margin: 5px 0 0 30px;
  ${(props) =>
    !props?.reverse &&
    css`
      margin: 10px 30px 0 0;
    `};

  @media (max-width: 1200px) {
    position: relative;
    vertical-align: middle;
    width: auto;
    height: 35px;
    margin-left: 10px;
    display: inline-block;
    margin: 0 0 0 20px;
  }
  @media (max-width: 768px) {
    height: 30px;
  }
`;
export const CommitmentTitle = styled.span`
  //position: relative;
  display: flex;
  margin-top: 20px;
  align-items: flex-start;

  @media (max-width: 580px) {
    font-size: 20px;
    margin-top: 0;
  }

  @media (max-width: 1024px) {
    margin-top: 0;
  }
`;

export const CommitmentTitleContainer = styled.div`
  font-size: 55px;
  line-height: 44px;
  font-weight: 600;
  font-stretch: expanded;
  margin: 20px 0 30px;

  text-align: ${(props) => (props.reverse ? "left" : "right")};
  color: ${(props) => props?.theme?.colors?.dark};

  & ${CommitmentTitle} {
    ${(props) =>
      !props?.reverse &&
      css`
        flex-direction: row-reverse;
      `};
  }

  @media (max-width: 1200px) {
    font-size: 20px;
    line-height: 24px;
    padding-left: 20px;

    margin: 0 0 10px;

    font-stretch: normal;
    font-weight: 800;
    text-align: left;

    & ${CommitmentTitle} {
      ${(props) =>
        !props?.reverse &&
        css`
          flex-direction: row;
        `};
    }
  }
`;
