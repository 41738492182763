import React from "react";
import PropType from "prop-types";
import { ButtonContainer, LinkContainer } from "./ActionButton.styled";
import { PAGES } from "constants/pages";
const ActionButton = (props) => {
  function handleClick() {
    if (typeof onClick === "function") {
      props.onClick();
    }
  }
  return props.isLink ? (
    <LinkContainer
      disabled={props.disabled}
      max_width={props.max_width}
      to={PAGES.CONTACT.route}
      smooth={true}
      $marginTop={props.marginTop}
    >
      {props.children}
    </LinkContainer>
  ) : (
    <ButtonContainer
      type={props.type}
      disabled={props.disabled}
      onClick={handleClick}
      max_width={props.max_width}
    >
      {props.children}
    </ButtonContainer>
  );
};

ActionButton.propTypes = {
  children: PropType.node,
  textTransform: PropType.oneOf(["uppercase", "capitalize"]),
  size: PropType.oneOf(["sm", "md", "lg", "xl"]),
  authButton: PropType.bool,
  variant: PropType.string,
  type: PropType.oneOf(["button", "submit", "reset"]),
  onClick: PropType.func,
  className: PropType.string,
  disabled: PropType.bool,
  minWidth: PropType.oneOf(["auto"]),
  hidden: PropType.bool,
  max_width: PropType.string,
  isLink: PropType.bool,
  marginTop: PropType.string,
};

ActionButton.defaultProps = {
  type: "button",
  isLink: false,
};

export default ActionButton;
