import styled from "styled-components";
import CardMedia from "@mui/material/CardMedia";
import { Grid } from "@mui/material";
import { handleColorType } from "../../../util/helpers/handleColorType";
export const PeopleCards = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 25px;
`;

export const PeopleCardImage = styled(CardMedia)`
  max-height: 480px;
  min-height: 460px;
  z-index: 1;
  position: relative;
  margin-top: auto;
  @media (max-width: 600px) {
    min-height: initial;
    height: 90%;
  }
`;

export const PeopleCardContainer = styled.div`
  background-color: ${(props) => props?.theme.colors[props?.$bgColor]};
  border-radius: 8px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 32px;
  max-width: 370px;

  @media (max-width: 959px) {
    width: 50%;
  }

  @media (max-width: 786px) {
    width: 80%;
  }

  @media (max-width: 480px) {
    width: 100%;
    padding: 5px;
  }
`;

export const PeopleCardImageCont = styled.div`
  position: relative;
  display: flex;

  height: 500px;
  width: 100%;

  @media (max-width: 600px) {
    height: 432px;
  }
`;

export const PeopleCardOverlay = styled.div`
  background-color: ${(props) =>
    handleColorType(props.backgroundType, props?.theme?.colors)};
  position: absolute;
  width: 100%;
  height: 75%;
  bottom: 0;
  left: 0px;
  z-index: 0;
  border-radius: 10px;

  @media (max-width: 600px) {
    height: 73%;
  }
`;
export const PeopleCardDetails = styled.div`
  font-size: 20px;
  padding: 0px; //20px 20px 0px
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
`;
export const PeopleCardTitle = styled.h3`
  color: ${(props) => props?.theme.colors?.dark};
  font-stretch: expanded;
  margin-top: 20px;
  font-size: 38px;
  line-height: 44px;
  white-space: nowrap;
  margin-bottom: 10px;
  display: inline-block;
  @media (max-width: 959px) {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 5px;
  }
`;
export const PeopleCardSubTitle = styled.p`
  color: ${(props) => props?.theme.colors?.dark};
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 10px;
  display: inline-block;
  font-stretch: expanded;
  margin-top: 0px;
  width: 306px;
  @media (max-width: 959px) {
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: 600px) {
    font-stretch: normal;
  }
`;
export const TechTeamImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 9.43548px;
  margin-bottom: 78px;
  @media (max-width: 959px) {
    margin: 32px 0px 64px;
  }
`;
