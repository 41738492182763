import React from "react";
import {
  CareersSelectionProcessCardContainer,
  CareersSelectionProcessCardParagraph,
  CareersSelectionProcessCardParagraphHeader,
  CareersSelectionProcessCardParagraphText,
  CareersSelectionProcessCardImage,
  Bold,
} from "./CareersSelectionProcessCard.styled";
import SubSectionText from "components/SubSectionText/SubSectionText";
import { Box } from "@mui/material";
import { Trans } from "react-i18next";
import PropTypes from "prop-types";

const CareersSelectionProcess = (props) => {
  return (
    <CareersSelectionProcessCardContainer>
      <CareersSelectionProcessCardParagraph $color={props.bgColor}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <CareersSelectionProcessCardImage
            item
            $src={props.image}
            $height={"180px"}
          />
          <CareersSelectionProcessCardParagraphHeader item>
            <Trans i18nKey={props.header} components={[<Bold key={0} />]} />
          </CareersSelectionProcessCardParagraphHeader>
        </Box>

        <CareersSelectionProcessCardParagraphText>
          <SubSectionText text={props.text} textColor={"dark"} />
        </CareersSelectionProcessCardParagraphText>
      </CareersSelectionProcessCardParagraph>
    </CareersSelectionProcessCardContainer>
  );
};

CareersSelectionProcess.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  text: PropTypes.string,
  header: PropTypes.string,
  bgColor: PropTypes.string,
  IsEvenRow: PropTypes.bool,
};

CareersSelectionProcess.defaultProps = {
  textColor: "white",
  IsEvenRow: false,
};

export default CareersSelectionProcess;
