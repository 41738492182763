import React from "react";
import Mission1 from "../../static/mission1.jpg";
import Mission2 from "../../static/mission2.jpg";
import SubSection from "./SubSection";
import i18next from "i18next";
import PropTypes from "prop-types";
const SubSections = (props) => {
  const missions = [
    {
      text: i18next.t("aboutPage.mission1"),
      image: Mission1,
      reverse: false,
      IsBold: props.IsBold,
    },
    {
      text: i18next.t("aboutPage.mission2"),
      image: Mission2,
      reverse: true,
      IsBold: props.IsBold,
    },
  ];

  return (
    <>
      {missions?.map((mission, index) => {
        return (
          <SubSection
            key={index}
            text={mission.text}
            image={mission.image}
            reverse={mission.reverse}
            applyPadding={props.applyPadding}
            IsBold={mission.IsBold}
          />
        );
      })}
    </>
  );
};
SubSections.propTypes = {
  applyPadding: PropTypes.any,
  IsBold: PropTypes.any,
};
export default SubSections;
