import styled, { css } from "styled-components";
import { CircularProgress } from "@mui/material";
import { Link as ScrollLink } from "react-scroll";
export const LinkContainer = styled(ScrollLink)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  text-align: center;
  text-transform: uppercase;
  font-family: "Mona-Sans", "Poppins", sans-serif;
  font-size: 24px;
  letter-spacing: 1.5px;
  font-weight: 600;
  line-height: 25px;

  border-radius: 16px;
  border: 0;

  width: 100%;
  max-width: ${(props) =>
    props.max_width !== undefined && props.max_width !== ""
      ? props.max_width
      : "379px"};

  padding: 38px 44px;
  ${(props) =>
    props.$marginTop !== undefined &&
    props.$marginTop !== "" &&
    css`
      margin-top: ${(props) => props.$marginTop};
    `};

  background-color: ${(props) => props?.theme.colors?.thertiary};
  color: ${(props) => props?.theme.colors?.dark};
  &:disabled {
    color: ${(props) => props?.theme.colors.secondGray};
  }

  & svg {
    margin-left: 7px;
  }

  & circle {
    r: 10;
    margin-top: 10px;
  }

  position: relative;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 20px;
    padding: 30px 44px;
  }
`;
export const ButtonContainer = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border: 0;
  border-radius: 16px;
  padding: 38px 44px;

  width: 100%;
  max-width: ${(props) =>
    props.max_width !== undefined && props.max_width !== ""
      ? props.max_width
      : "379px"};

  font-family: "Mona-Sans", "Poppins", sans-serif;
  text-align: center;
  text-transform: uppercase;

  letter-spacing: 1.5px;
  font-size: 24px;
  font-weight: 600;
  line-height: 25px;

  position: relative;

  background-color: ${(props) => props?.theme.colors?.thertiary};

  &:disabled {
    color: ${(props) => props?.theme.colors.secondGray};
  }
  & svg {
    margin-left: 7px;
  }
  & circle {
    r: 10;
    margin-top: 10px;
  }
  @media (max-width: 768px) {
    font-size: 20px;
    padding: 30px 44px;
  }
`;
export const Spinner = styled(CircularProgress)`
  width: 18px;
  height: 18px;
  color: ${(props) => props?.theme.colors?.secondGray};
  position: relative;
  top: 10px;
  position: absolute;
  top: 27%;
`;
