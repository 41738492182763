import styled from "styled-components";
import { Box } from "@mui/material";

export const CardContainer = styled(Box)`
  font-family: "Mona-Sans", sans-serif;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props?.theme.colors.white};

  height: 374px;
  width: 486px;
  padding: 32px 0 0;
  margin-right: 16px;
  border-radius: 16px;
  font-weight: 300;

  @media (max-width: 1024px) {
    width: 275px;
  }
`;

export const ClientMessage = styled(Box)`
  width: 100%;
  padding: 0 20px;
  flex-flow: column;
  flex: 1;
  font-size: 18px;
  line-height: 125%;
  display: flex;
  color: ${(props) => props?.theme.colors.dark};

  @media (max-width: 1024px) {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 125%;
  }
`;

export const ClientInfo = styled(Box)`
  font-size: 18px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  //flex-wrap: wrap;
  height: 107px;
  padding: 0 30px;

  background-color: ${(props) => props?.theme.colors.thertiary};

  @media (max-width: 1024px) {
    padding: 0 10px;
    height: 78px;
  }
`;

export const Clinet = styled(Box)`
  font-size: 18px;
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
`;

export const ClinetImage = styled(Box)`
  background-image: url(${(props) => props?.$src});
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  height: 60px;
  width: 60px;

  @media (max-width: 959px) {
    height: 45px;
    width: 45px;
  }
`;

export const ClientName = styled(Box)`
  font-size: 20px;
  font-weight: 600;
  font-family: "Mona-Sans", sans-serif;
  font-stretch: semi-expanded;
  line-height: 125%;

  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;
export const ClientPosition = styled(Box)`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-family: "Mona-Sans", sans-serif;
  font-weight: 200;
  line-height: 30px;
  text-decoration: none;

  @media (max-width: 1024px) {
    font-size: 12px;
    line-height: initial;
    font-stretch: normal;
    color: ${(props) => props?.theme.colors?.dark};
    text-decoration: none solid ${(props) => props?.theme.colors?.dark};
    text-align: none solid ${(props) => props?.theme.colors?.dark};
  }
`;

export const BusinessImage = styled.img`
  margin: auto 0;

  @media (max-width: 1024px) {
    max-height: 35px;
  }
`;
