import React from "react";
import Logo from "components/Logo/Logo";
import { NavLink } from "react-router-dom";
import { PAGES } from "constants/pages";
import { footerNavigationConstants } from "constants/navigationConstants";
import HeaderNavigationItem from "../../components/Header/HeaderNavigation/HeaderNavigationItem/HeaderNavigationItem";
import {
  FooterSection,
  FooterMenu,
  FooterBottomContainer,
  FooterCopyRight,
  CopyRightLink,
  CopyRightText,
  FooterDevider,
  DividerLine,
} from "./Footer.style";
import Section from "components/Section/Section";
import SocialNetworks from "./SocialNetworks/SocialNetworks";
import useDevice from "hooks/useDevice";
const FooterBottom = () => {
  const { isMobile } = useDevice();
  return (
    <Section fullWidth={false}>
      <FooterBottomContainer fullWidth={false}>
        {isMobile && (
          <FooterDevider>
            <DividerLine />
          </FooterDevider>
        )}
        <FooterSection>
          <Logo
            width="auto"
            light
            height="30px"
            as={NavLink}
            to={PAGES.BASE.route}
          />
        </FooterSection>
        <FooterSection>
          <FooterMenu isCentered={true}>
            {footerNavigationConstants.map?.((singleNavigationItem, index) => (
              <HeaderNavigationItem key={index} route={singleNavigationItem} />
            ))}
          </FooterMenu>
        </FooterSection>
        <FooterSection textAlign="right">
          <SocialNetworks />
        </FooterSection>
      </FooterBottomContainer>
      <FooterCopyRight>
        <CopyRightLink
          href="https://www.tremium.net/private-policy"
          target="_blank"
        >
          Privacy Policy
        </CopyRightLink>
        <CopyRightText>
          Copyright © {new Date().getFullYear()}. Tremium. <br />
          All rights reserved.
        </CopyRightText>
      </FooterCopyRight>
    </Section>
  );
};
export default FooterBottom;
