import React from "react";
import {
  CommitmentIcon,
  CommitmentTitle,
  CommitmentTitleContainer,
  CommitmentTextContainer,
  CommitmentTextInnerContainer,
} from "./Commitment.style";
import PropTypes from "prop-types";

const CommitmentText = (props) => {
  return (
    <CommitmentTextInnerContainer>
      <CommitmentTitleContainer reverse={props.reverse}>
        <CommitmentTitle>
          {props.title}
          <CommitmentIcon
            iconProperties={props.iconProperties}
            reverse={props.reverse}
            src={props.icon}
          />
        </CommitmentTitle>
      </CommitmentTitleContainer>
      <CommitmentTextContainer reverse={props.reverse}>
        {props.text}
      </CommitmentTextContainer>
    </CommitmentTextInnerContainer>
  );
};
CommitmentText.propTypes = {
  title: PropTypes.any,
  text: PropTypes.string,
  reverse: PropTypes.bool,
  background: PropTypes.any,
  icon: PropTypes.any,
  iconProperties: PropTypes.any,
};
export default CommitmentText;
