import { Box, IconButton } from "@mui/material";
import styled, { css } from "styled-components";

export const HeaderIconContainer = styled(IconButton)`
  cursor: pointer;
  min-width: 0;
  border-radius: 100%;
  &:hover {
    background-color: ${(props) => props?.theme?.colors?.white};
  }
  & svg {
    fill: ${(props) => props?.theme?.colors?.textColor};
  }
`;

export const HeaderContainer = styled(Box)`
  display: flex;
  position: fixed;

  z-index: 1201;
  top: 0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 calc(max((100vw - 1170px) / 2, 14.5px));
  height: 70px;
  background-color: ${(props) => props?.theme.colors.white};

  transform-style: preserve-3d;

  ${(props) =>
    props?.$hide &&
    css`
      transition: transform 0.3s ease-in-out;
      transform: translate3d(0px, -100px, 0px);
    `}

  ${(props) =>
    !props?.$hide &&
    css`
      transition: transform 0.5s ease-in-out;
      transform: translate3d(0px, 0px, 0px);
    `}

    @media (max-width: 1024px) {
    height: 60px;
  }
`;
export const HeaderSideContainer = styled(Box)`
  display: flex;
  gap: 24px;
  height: 100%;
  width: 100%;
  align-items: center;
  padding-left: 20px;
  justify-content: space-between;
`;
