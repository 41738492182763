import React from "react";
import PeopleCard from "components/Cards/PeopleCard/PeopleCard";
import { PeopleCards } from "components/Cards/PeopleCard/PeopleCard.styled";
import { Trans, useTranslation } from 'react-i18next';
import Section from "components/Section/Section";
import { TitleHighlight } from "components/Section/Section.styled";
import PropTypes from "prop-types";
const Team = (props) => {
    const {t} = useTranslation();
    return (
        <>  
            <Section cardTitle={ 
                <Trans
                    t={t}
                    i18nKey={props.title} 
                    components={[<TitleHighlight key={0} isStyled={true} isText={false} />]} 
                />} 
                titleAlignLeft={props.titleAlignLeft}
                margin={props.margin}
                paddingBottom={props.paddingBottom}
            > 
            <PeopleCards container > {  
                        props.team?.map((teamMember, index) => {
                           return  <PeopleCard  key={index}
                                    title={teamMember.title} 
                                    subTitle={teamMember.subTitle} 
                                    image={teamMember.image} 
                                    backgroundType={teamMember.backgroundType}/>     
                        })
                    }
            </PeopleCards> 
            </Section>          
        </>
    );
}
Team.propTypes = {
    team: PropTypes.any,
    title: PropTypes.any,
    titleAlignLeft: PropTypes.any,
    margin: PropTypes.string,
    paddingBottom: PropTypes.string
};
Team.defaultProps = {
    titleAlignLeft: false
};
export default Team;