import React from "react";
import vesna_kostic from "../../static/vesna_kostic.png";
import emilija_stojiljkovic from "../../static/emilija_stojiljkovic.png";
import dusan_dimic from "../../static/dusan_dimic.png";
import i18next from "i18next";
import { BackgroundType } from "constants/backgroundType";
import Team from "./Team";
import useDevice from "hooks/useDevice";
const CoreTeam = () => {
  const { isMobile } = useDevice();
  const team = [
    {
      title: i18next.t("aboutPage.ourTeam.People.Vesna.Name"),
      subTitle: i18next.t("aboutPage.ourTeam.People.Vesna.Role"),
      image: vesna_kostic,
      backgroundType: BackgroundType.DARK_BLUE,
    },
    {
      title: i18next.t("aboutPage.ourTeam.People.Emilija.Name"),
      subTitle: i18next.t("aboutPage.ourTeam.People.Emilija.Role"),
      image: emilija_stojiljkovic,
      backgroundType: BackgroundType.YELLOW,
    },
    {
      title: i18next.t("aboutPage.ourTeam.People.Dusan.Name"),
      subTitle: i18next.t("aboutPage.ourTeam.People.Dusan.Role"),
      image: dusan_dimic,
      backgroundType: BackgroundType.BLUE,
    },
  ];
  return (
    <Team
      margin={isMobile ? "6px 10px 10px" : "104px 10px 0"}
      paddingBottom={isMobile ? "0px" : "15px"}
      title="aboutPage.ourTeam.People.Title"
      team={team}
    />
  );
};
export default CoreTeam;
