import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { SectionContainer, SectionTitle } from "./Section.styled";

const Section = (props) => {
  const [color, setColor] = useState("dark");
  const [borderColor, setBorderColor] = useState("primary");
  useEffect(() => {
    if (props.bgColor == "dark") {
      setColor("white");
    }
    setBorderColor("secondaryOpaque");
  }, []);
  return (
    <SectionContainer
      container
      $fullWidth={props.fullWidth}
      $bgColor={props.bgColor}
    >
      <Grid item xs={12}>
        {props.cardTitle && (
          <SectionTitle
            $color={color}
            $borderColor={borderColor}
            $alignLeft={props.titleAlignLeft}
            margin={props.margin}
            paddingBottom={props.paddingBottom}
            lineHeight={props.lineHeight}
          >
            {props.cardTitle}
          </SectionTitle>
        )}
      </Grid>
      <Grid item xs={12}>
        {props.children}
      </Grid>
    </SectionContainer>
  );
};
Section.propTypes = {
  fullWidth: PropTypes.any,
  children: PropTypes.node,
  cardTitle: PropTypes.any,
  bgColor: PropTypes.string,
  titleAlignLeft: PropTypes.bool,
  margin: PropTypes.string,
  paddingBottom: PropTypes.string,
  lineHeight: PropTypes.string,
};

Section.defaultProps = {
  lineHeight: "80px",
  paddingBottom: "36px",
  margin: "140px 10px 40px",
  cardTitle: "",
  titleAlignLeft: true,
};

export default Section;
