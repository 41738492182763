import React , {useEffect, useState}
 from 'react';
import PropTypes from "prop-types";
import { FormGroupContainer, Label, Input, Required } from './CustomForm.style'
import CustomFileInput from './CustomFileInput'
import Textarea from './Textarea'
const FormGroup = (props) => {
    const { type, name, label, accept, isRequired, isSubmited} = props;    
    const [inputValue, setInputValue] = useState('');
    const handleInputChange = (e, value) => {
        props.onChange(name, e === null || e === undefined ? value : e.target.value);      
        setInputValue(e === null || e === undefined ? value : e.target.value);  
    }
    const handleTextareaChange = (newValue) => {
        props.onTextareaChange(newValue);
    }
    useEffect(() => {
        setInputValue('');
    }, [isSubmited])
    return (
        <FormGroupContainer>
            {
                label !== '' && label !== undefined &&
                <Label htmlFor={name}>
                    <span>{label}</span>
                    { isRequired && <Required >*</Required> }
                </Label>
            }
            { type !== "message" ?
                                type === "file" ? <CustomFileInput isSubmited={isSubmited} handleInputChange={handleInputChange} name={name} accept={accept} /> :
                                                  <Input value={inputValue} onChange={handleInputChange}
                                                   type={type} name={name} id={name} accept={accept} /> :
                                <Textarea isSubmited={isSubmited} value={inputValue} onTextareaChange={handleTextareaChange}
                             />
            }
        </FormGroupContainer>
    );
}
FormGroup.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    accept : PropTypes.string,
    isRequired : PropTypes.bool,
    onTextareaChange: PropTypes.func,
    onChange: PropTypes.func,
    isSubmited: PropTypes.bool
};
FormGroup.defaultProps = {
  type:"text"
}
export default FormGroup;
