import React from "react";
import PropTypes from "prop-types";
import {
  CardContainer,
  ClientMessage,
  ClientInfo,
  Clinet,
  ClinetImage,
  BusinessImage,
  ClientName,
  ClientPosition,
} from "./ClientCard.styled";
import { Box } from "@mui/material";

const ClientCard = (props) => {
  return (
    <CardContainer>
      <ClientMessage>{props.message} </ClientMessage>
      <ClientInfo>
        <Clinet>
          {props.persionImg && <ClinetImage $src={props.persionImg} />}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <ClientName>{props.persionName}</ClientName>
            <ClientPosition>{props.persionPosition}</ClientPosition>
          </Box>
        </Clinet>
        <BusinessImage
          src={props.businessImg}
          height={props.businessImgHeight}
          width={props.businessImgWidth}
        ></BusinessImage>
      </ClientInfo>
    </CardContainer>
  );
};

ClientCard.propTypes = {
  persionName: PropTypes.string,
  persionPosition: PropTypes.object,
  message: PropTypes.string,
  persionImg: PropTypes.string,
  businessImg: PropTypes.string,
  businessImgWidth: PropTypes.string,
  businessImgHeight: PropTypes.string,
};

ClientCard.defaultProps = {
  businessImgWidth: "auto",
  businessImgHeight: "55px",
};

export default ClientCard;
