import styled from "styled-components";
import { Typography } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";

export const CardContainer = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  min-width: 280px;
  max-width: 280px;
  min-height: 520px;
  border-radius: 16px;
  background-color: ${(props) => props?.theme.colors[props?.$bgColor]};
  @media (max-width: 959px) {
    font-size: 4vw;
    min-height: 350px;
    margin-bottom: 8px;
  }
`;

export const CardImage = styled(CardMedia)`
  border-radius: 16px 16px 0 0;
  max-width: 100%;
  height: 160px;

  @media (max-width: 959px) {
    height: 100px;
  }
`;

export const CardDetails = styled.div`
  font-size: 20px;
  padding: 10px 20px 0px 20px;
  li::marker {
    font-size: 21px;
  }
`;

export const CardTitle = styled(Typography)`
  color: ${(props) => props?.theme.colors?.dark};
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 16px;
  font-stretch: expanded;
  font-size: 32px;
  font-weight: 700;
  line-height: 125%;
  display: block;

  @media (max-width: 959px) {
    font-size: 20px;
  }
`;
