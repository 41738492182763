import React from "react";
import tech_team from "../../static/tech_team.jpg";
import { Trans, useTranslation } from "react-i18next";
import { TitleHighlight } from "components/Section/Section.styled";
import Section from "components/Section/Section";
import PropTypes from "prop-types";
import { TechTeamImage } from "../../components/Cards/PeopleCard/PeopleCard.styled";
import i18next from "i18next";
import useDevice from "hooks/useDevice";
const TechTeam = () => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();
  return (
    <Section
      cardTitle={
        <Trans
          t={t}
          i18nKey={i18next.t("aboutPage.ourTeam.Technical.Title")}
          components={[
            <TitleHighlight key={0} isStyled={true} isText={false} />,
          ]}
        />
      }
      titleAlignLeft={false}
      margin={isMobile ? "64px 0px 0px" : "71px 10px 28px"}
      paddingBottom={isMobile ? "2px" : "15px"}
    >
      <TechTeamImage src={tech_team} />
    </Section>
  );
};
TechTeam.propTypes = {
  title: PropTypes.any,
};
export default TechTeam;
