import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  HeaderNavigationItemContainer,
  HeaderNavigationItemLinkContainer,
  HeaderNavigationItemIconContainer,
  HeaderNavigationItemIconTitle,
} from "./HeaderNavigationItem.styled";
import { useLocation } from "react-router-dom";
import { compareRoutes, isInRoute } from "util/routeHelpers";
import { PAGES } from "constants/pages";

const HeaderNavigationItem = (props) => {
  const location = useLocation();
  const singleRoutePath = useMemo(() => {
    const pathArray = props?.route?.route?.split("/");
    if (pathArray?.[pathArray?.length - 1] === "*")
      pathArray.splice(pathArray?.length - 1, 1);
    return pathArray.join("/");
  }, [props?.route]);

  const highlightTitle = useMemo(() => {
    return compareRoutes(singleRoutePath, PAGES.CONTACT.route);
  }, [props?.route]);

  const isInThisRoute = useMemo(() => {
    if (
      location?.pathname === "/" &&
      compareRoutes(singleRoutePath, PAGES.HOME.route)
    )
      return true;
    return isInRoute(location.pathname, singleRoutePath);
  }, [location.pathname, singleRoutePath]);

  return (
    singleRoutePath ===  PAGES.CONTACT.route ?
    <HeaderNavigationItemLinkContainer
      {...props}
      to={singleRoutePath}
      $isActive={isInThisRoute}
      smooth={true}
    >
      {props?.route?.icon && (
        <HeaderNavigationItemIconContainer>
          {props?.route?.icon}
        </HeaderNavigationItemIconContainer>
      )}
      <HeaderNavigationItemIconTitle
        $isActive={isInThisRoute}
        $highlightTitle={highlightTitle}
      >
        {props?.route?.title}
      </HeaderNavigationItemIconTitle>
    </HeaderNavigationItemLinkContainer>
    : <HeaderNavigationItemContainer
      {...props}
      to={singleRoutePath}
      $isActive={isInThisRoute}
    >
      {props?.route?.icon && (
        <HeaderNavigationItemIconContainer>
          {props?.route?.icon}
        </HeaderNavigationItemIconContainer>
      )}
      <HeaderNavigationItemIconTitle
        $isActive={isInThisRoute}
        $highlightTitle={highlightTitle}
      >
        {props?.route?.title}
      </HeaderNavigationItemIconTitle>
    </HeaderNavigationItemContainer>
  );
};

HeaderNavigationItem.propTypes = {
  route: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
    title: PropTypes.string,
    subroutes: PropTypes.array,
    route: PropTypes.string,
  }),
};

export default HeaderNavigationItem;
