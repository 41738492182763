import React from "react";
import PropTypes from "prop-types";
import {CustomTabPanelInnerContainer} from './Footer.style'
const CustomTabPanelRight = (props) => {
return (
   <CustomTabPanelInnerContainer>
        {props.children}
   </CustomTabPanelInnerContainer>
  );
};
CustomTabPanelRight.propTypes = {
    children: PropTypes.any
};
export default CustomTabPanelRight;