import React from "react";
import {
  CareersSelectionProcessContainer,
  CareersSelectionProcessParagraph,
  CareersSelectionProcessConnection,
  CareersSelectionProcessParagraphHeader,
  CareersSelectionProcessParagraphText,
  CareersSelectionProcessImageContainer,
  CareersSelectionProcessImage,
  Bold,
} from "./CareersSelectionProcess.styled";
import SubSectionText from "components/SubSectionText/SubSectionText";
import { Trans } from "react-i18next";
import PropTypes from "prop-types";

const CareersSelectionProcess = (props) => {
  return (
    <CareersSelectionProcessContainer>
      <CareersSelectionProcessImageContainer
        $height={180}
        $color={props.bgColor}
      >
        <CareersSelectionProcessImage $src={props.image} $height={"180px"} />
      </CareersSelectionProcessImageContainer>
      <CareersSelectionProcessConnection
        $color={props.bgColor}
        $IsEvenRow={props.IsEvenRow}
      ></CareersSelectionProcessConnection>
      <CareersSelectionProcessParagraph $color={props.bgColor}>
        <CareersSelectionProcessParagraphHeader>
          <Trans i18nKey={props.header} components={[<Bold key={0} />]} />
        </CareersSelectionProcessParagraphHeader>
        <CareersSelectionProcessParagraphText>
          <SubSectionText text={props.text} textColor={"dark"} />
        </CareersSelectionProcessParagraphText>
      </CareersSelectionProcessParagraph>
    </CareersSelectionProcessContainer>
  );
};

CareersSelectionProcess.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
  text: PropTypes.string,
  header: PropTypes.string,
  bgColor: PropTypes.string,
  IsEvenRow: PropTypes.bool,
};

CareersSelectionProcess.defaultProps = {
  textColor: "white",
  IsEvenRow: false,
};

export default CareersSelectionProcess;
