import { BackgroundType } from "constants/backgroundType";

export const handleColorType = (type, colors) => {
    switch (type) {
      case BackgroundType.WHITE:
        return colors.secondTextColor;
      case BackgroundType.BLUE:
        return colors.secondaryOpaque;
      case BackgroundType.DARK_BLUE:
        return colors.dark;
      default:
        return colors.thertiary;
    }
};