import React , { useEffect, useState } from 'react';
import CustomTabPanel from '../CustomTabPanel'
import CustomTabPanelLeft from "../CustomTabPanelLeft";
import CustomTabPanelRight from "../CustomTabPanelRight";
import FormGroup from "../../../components/CustomForm/FormGroup";
import CustomForm from 'components/CustomForm/CustomForm';
import { useTranslation } from 'react-i18next';
import apiEndpoints from "request/apiEndpoints";
import PropTypes from "prop-types";
import { Ul, Li } from '../../../components/Ul/Ul.styled'
import { Reason, ReasonIcon} from '../Footer.style'
import reason_icon from '../../../static/reason_icon.svg'
import i18next from 'i18next';
import { validateEmail } from '../../../util/helpers/validateEmail';
const DoBusinessTab = (props) => {
    const {value, index} = props;
    const { t } = useTranslation();
    const doBusinessReasons = t('footer.doBusinessReasons', { returnObjects: true });
    const [isValid, setIsValid] = useState(false)
    const [isSubmited, setIsSubmited] = useState(false);
    const initialState = {
      Name: '',
      Email: '',
      CompanyName: ''
    };
    const [formData, setFormData] = useState(initialState);   
    const handleInputChange = (name, value) => {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    };
    useEffect(() => {
     let  existsError = false;
      for (const [field, value] of Object.entries(formData)) {
        if (!value || value === '' || (field === "Email" && validateEmail(value)  === null )) {
          existsError = true;
          break; 
        }
      }
      setIsValid(!existsError);  
      setIsSubmited(false);      
    }, [formData]);    
   
    const submited = () => {
      setIsSubmited(true);
    }
    return (
        <CustomTabPanel value={value} index={index}>
          <CustomTabPanelLeft>
          <CustomForm isValid={isValid} action={apiEndpoints.contact.doBusiness} isSubmited={isSubmited} submited={submited}>
              <FormGroup isSubmited={isSubmited} onChange={handleInputChange} name={i18next.t('footer.doBusinessForm.fullName.name')} label={i18next.t('footer.doBusinessForm.fullName.label')} isRequired={true} />
              <FormGroup isSubmited={isSubmited} onChange={handleInputChange} name={i18next.t('footer.doBusinessForm.company.name')} label={i18next.t('footer.doBusinessForm.company.label')} isRequired={true} />                     
              <FormGroup isSubmited={isSubmited} onChange={handleInputChange} name={i18next.t('footer.doBusinessForm.email.name')} label={i18next.t('footer.doBusinessForm.email.label')} isRequired={true} /> 
            </CustomForm  >
          </CustomTabPanelLeft>
          <CustomTabPanelRight>
              <Ul>
                {
                  doBusinessReasons.map((reason, i) => (<Li key={i}><ReasonIcon src={reason_icon} alt="icon" /><Reason>{reason}</Reason></Li>))
                }
              </Ul>
          </CustomTabPanelRight>
          </CustomTabPanel>
    );
}
DoBusinessTab.propTypes = {
    index: PropTypes.any,
    value: PropTypes.any
};
export default DoBusinessTab;