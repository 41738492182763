import { Box } from "@mui/material";
import styled from "styled-components";
import { SubSectionContainerText } from "components/SubSection/SubSection.styled";

export const CareersSelectionProcessContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  padding-bottom: 32px;
  &:last-of-type {
    padding-bottom: 151px;
  }
`;

export const CareersSelectionProcessParagraph = styled(Box)`
  grid-row-gap: 15px;
  background-color: ${(props) => props?.theme.colors[props?.$color ?? "white"]};
  border-radius: 15.4348px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 712px;
  padding: 33px 29px;
  text-decoration: none;
  display: flex;
`;

export const CareersSelectionProcessParagraphHeader = styled(Box)`
  font-size: 27px;
  font-weight: 700;
  font-stretch: expanded;
`;

export const CareersSelectionProcessParagraphText = styled(Box)`
  & ${SubSectionContainerText} {
    width: 100%;
    margin: 0;
    max-width: none;
    font-size: 21px;
    font-weight: 400;
    line-height: 150%;
    color: ${(props) => props?.theme.colors.dark};
  }
`;

export const CareersSelectionProcessImageContainer = styled(Box)`
  padding: 12px;
  border-radius: 50%;
  background-color: ${(props) => props?.theme.colors[props?.$color ?? "white"]};
  height: ${(props) => (props.$height ?? 100) + 12 * 2 + "px"};
`;

export const CareersSelectionProcessImage = styled.div`
  background-image: url(${(props) => props?.$src});
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  width: ${(props) => props?.$height};
  height: ${(props) => props?.$height};
`;

export const Bold = styled.span`
  font-weight: 700;
  font-stretch: expanded;
`;

export const CareersSelectionProcessConnection = styled(Box)`
  height: 20px;
  background-color: yellow;
  margin-left: -5px;
  background-color: ${(props) => props?.theme.colors[props?.$color ?? "white"]};
  width: ${(props) => (props?.$IsEvenRow ? "105px" : "200px")};
`;
