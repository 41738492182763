import React from "react";
import {
  CareersBannerContainer,
  CareersBannerParagraph,
  CareersBannerParagraphHeader,
  CareersBannerParagraphText,
  CareersBannerImage,
  Bold,
} from "./CareersBanner.styled";
import SubSectionText from "components/SubSectionText/SubSectionText";
import Image from "components/Image/Image";
import { Trans } from "react-i18next";

import imgBanner from "assets/images/pages/careers/banner.jpg";
import ActionButton from "components/Button/ActionButton/ActionButton";
import { ReactComponent as ButtonIcon } from "assets/images/svg/arr-right.svg";
import useDevice from "hooks/useDevice";

const CareersBanner = () => {
  const { isMobile } = useDevice();
  const paddingBottom = isMobile ? "35px" : "85px";
  return (
    <CareersBannerContainer>
      <CareersBannerParagraph>
        <CareersBannerParagraphHeader>
          <Trans
            i18nKey="careersPage.intro.header"
            components={[<Bold key={0} />]}
          />
        </CareersBannerParagraphHeader>
        <CareersBannerParagraphText>
          <SubSectionText text={"careersPage.intro.text"} textColor={"dark"} />
        </CareersBannerParagraphText>
        <CareersBannerParagraphText sx={{ pb: paddingBottom }}>
          <ActionButton
            size={"lg"}
            isLink={true}
            marginTop={isMobile ? "0px" : "37px"}
          >
            <Trans i18nKey="common.apply" />
            <ButtonIcon></ButtonIcon>
          </ActionButton>
        </CareersBannerParagraphText>
      </CareersBannerParagraph>
      <CareersBannerImage>
        <Image image={imgBanner} height={"464"}></Image>
      </CareersBannerImage>
    </CareersBannerContainer>
  );
};

export default CareersBanner;
