import styled from "styled-components";
// Replace with styled.img when there is logo
export const LogoContainer = styled.div`
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  margin-top: auto;
  margin-bottom: auto;
  color: ${(props) => props?.theme?.colors?.textColor};
`;

export const LogoImage = styled.img`
  text-decoration: none;
  cursor: pointer;
  margin-top: 5px;
  max-height: ${(props) => props.height ?  props.height : "27px"};
  max-width: ${(props) => props.width ?  props.width : "160px"};

  @media (max-width: 1024px) {
    max-height: 22px;
  }
`;
