import { PAGES } from "./pages";

export const headerNavigationConstants = [
  PAGES.HOME,
  PAGES.ABOUT,
  PAGES.CAREERS,
  PAGES.CONTACT,
];
export const footerNavigationConstants = [
  PAGES.HOME,
  PAGES.ABOUT,
  PAGES.CAREERS
];
