import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import { SubSectionContainerText } from "components/SubSection/SubSection.styled";
import { Highlight } from "components/Hightlight/Hightlight.style";
import { ButtonContainer } from "components/Button/ActionButton/ActionButton.styled";
import Wrapper from "assets/images/svg/vectors_wrapper_bottom_left_w.svg";
import WrapperAlt from "assets/images/svg/bottom_left_hover_w.svg";
import {
  HighlightLinkContener,
  HighlightText,
  Link,
} from "components/Hightlight/Hightlight.style";

export const ServiceLineUpContainer = styled(Box)`
  gap: 49px;
  margin-top: 122px;
  margin-bottom: 80px;
  font-size: 20px;
  letter-spacing: 0.18px;
  line-height: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 1024px) {
    gap: 10px;
    display: flex;
    flex-direction: row;
    background-color: ${(props) => props?.theme.colors?.dark};
    overflow-x: auto;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.0893506px;
  }
`;

export const ServiceLineUpParagraphHeader = styled(Box)`
  border-top-right-radius: 16px;
  font-size: 56px;
  display: block;
  font-stretch: expanded;
  line-height: 82px;
  letter-spacing: -1px;
  color: ${(props) => props?.theme.colors?.white};
  background-color: ${(props) => props?.theme.colors?.dark};
  width: auto;
  font-weight: 300;
  position: relative;
  padding: 0 20px 0 25px;
  min-width: calc(561.5px - ${(props) => props?.imgWidth});

  ${(props) =>
    !props?.radius &&
    css`
      &:last-of-type {
        border-top-right-radius: 0px;
      }
    `}

  &:first-of-type {
    padding-top: 10px;
    border-top-right-radius: 0px;
    border-top-left-radius: 13px;
  }

  @media (max-width: 1024px) {
    font-size: 28px;
    letter-spacing: -0.496392px;
    padding-right: 10px;
    padding-left: 10px;
    font-weight: 300;
    line-height: 40.7px;
    white-space: nowrap;
    min-width: calc((260px - ${(props) => props?.imgWidth}));

    & ${Highlight} {
      font-weight: 800;
    }

    &:first-of-type {
      padding-top: 10px;
    }
  }
`;
export const ServiceLineUpParagraphHeaderAlt = styled(
  ServiceLineUpParagraphHeader
)`
  border-top-right-radius: 16px;
  ${(props) =>
    !props?.radius &&
    css`
      border-top-right-radius: 0px;
    `}
  &:after {
    transform: translateX(18px);
    position: absolute;
    z-index: -1;
    content: "";
    background-image: url(${Wrapper});
    background-size: cover;
    background-position: center;
    height: 20px;
    width: 20px;
    bottom: 0;
    right: 0;
    // @media (max-width: 1170px) {
    //   background: none;
    // }
  }
`;
export const ServiceLineUpParagraphHeaderDevops = styled(
  ServiceLineUpParagraphHeaderAlt
)`
  padding-right: 60px;
  @media (max-width: 1024px) {
    padding-right: 10px;
  }
`;
export const ServicesLineUpTextContainer = styled.div`
  color: ${(props) => props?.theme?.colors.secondTextColor};
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  position: relative;
  padding-top: 10px;
  padding-left: 20px;
  padding: 4px 27px;

  ${(props) =>
    props?.marginTop !== undefined &&
    props?.marginTop !== "" &&
    css`
      margin-top: ${(props) => props?.marginTop};
    `}
  @media (max-width: 1024px) {
    background-color: ${(props) => props?.theme.colors?.dark};
    padding: 5px 0 0 10px;
  }
`;
export const ServicesLineUpText = styled.div`
  ${Highlight} {
    line-height: unset;
    padding: 3px 1px;
    letter-spacing: 0.18px;
  }
`;
export const Circle = styled.div`
  border-radius: 160px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 62px;
  height: 62px;
  background-color: ${(props) => props?.theme?.colors.secondaryOpaque};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 62px;

  & svg {
    transform: rotate(90deg);
    transition: transform 0.3s ease-in-out;
    -webkit-transition: transform 0.3s ease-in-out;
    -moz-transition: transform 0.3s ease-in-out;
  }
  @media (max-width: 1024px) {
    width: 30px;
    height: 30px;

    & svg {
      transform: unset;
      transition: unset;
      -webkit-transition: unset;
      -moz-transition: unset;
      width: 12px;
    }
  }
`;
export const CircleContainer = styled.div`
  height: 82px;
  width: 100%;
  @media (max-width: 1024px) {
    position: abolute;
    botton: 0px;
    right: 0px;
  }
`;
export const ServiceLineInnerContainer = styled.div`
  flex-direction: row;
  justify-content: center;
  //align-items: flex-end;
  text-decoration: none;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  height: 465px;
  border-radius: 30px;
  ${(props) =>
    !props?.isMobile &&
    css`
      &:hover {
        background-color: ${(props) => props?.theme?.colors.thertiary};
        & ${ServiceLineUpParagraphHeader}, & ${ServicesLineUpTextContainer} {
          background-color: ${(props) => props?.theme?.colors.thertiary};
          color: ${(props) => props?.theme?.colors.dark};
        }
        & ${Circle} svg {
          transform: rotate(0deg);
        }
        &:hover ${ServiceLineUpParagraphHeaderAlt}:after {
          background-image: url(${WrapperAlt});
        }
      }
    `}
  @media (max-width: 1024px) {
    // height:424px;
    height: unset;
  }
`;
export const ServiceLineUpImageParagraphContainer = styled(Box)`
  width: 100%;
`;
export const ServiceLineUpImageParagraph = styled(Box)`
  grid-row-gap: 10px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 100%;
  border-top-right-radius: 16px;
  @media (max-width: 1024px) {
    display: flex;
    position: relative;
    right: unset;
    top: unset;
    width: 260px;
  }
`;
export const ServiceLineUpParagraph = styled(Box)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
  z-index: 1;
  border-top-left-radius: 16px;
`;
export const ServiceLineUpParagraphText = styled(Box)`
  max-width: 100%;
  display: flex;
  flex-direction: row;
  & ${HighlightLinkContener}, & ${HighlightText}, & ${Link} {
    width: 100%;
    max-width: 370px;
    font-size: 24px;
    font-weight: 400;
    line-height: 150%;
  }
  & ${SubSectionContainerText}, & ${HighlightLinkContener} {
    background-color: ${(props) => props?.theme.colors?.white};
    padding: 0px 8px 4px 8px;
    margin: 0;
  }

  & ${HighlightText} {
    margin-top: 8px;
    margin-left: -2px;
  }
  @media (max-width: 1170px) {
    justify-content: center;
    & ${HighlightLinkContener} {
      font-size: 16px;
      line-height: 24px;
      margin-top: 65px;
      padding: 0 10px 0 0;
    }

    & ${Highlight}, & ${HighlightText}, & ${Link}, & ${HighlightLinkContener} {
      font-size: 16px;
      max-width: 95%;
      line-height: 24px;
    }

    & ${HighlightText} {
      margin-top: 4px;
      margin-left: -2px;
    }
  }

  @media (max-width: 512px) {
    & ${Highlight} {
      display: inline;
    }
  }
`;
export const ServiceLineUpImageContainer = styled.div`
  row-gap: 10px;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
  position: absolute;
  ${(props) =>
    props?.left &&
    css`
      left: 0px;
    `}
  ${(props) =>
    !props?.left &&
    css`
      right: 0px;
    `}
  height:100%;
  width: 100%;
`;
export const ServiceLineUpImage = styled.div`
  border-top-left-radius: 0;
  border-top-right-radius: 20px;
  max-width: 100%;
  display: inline-block;
  background-image: url(${(props) => props.src});
  background-position: ${(props) => props.position};
  background-repeat: no-repeat;
  background-size: cover;

  height: calc(100% - 1px);
  width: ${(props) => props.imgWidth};
  margin-left: auto;
`;

export const Bold = styled.span`
  font-weight: 800;
  font-stretch: expanded;
`;

export const ServiceLineUpParagraph2 = styled(Box)`
  gap: 25px;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  & ${ButtonContainer} {
    min-width: 379px;
    height: 100%;
    display: flex;
    width: 100%;
    max-width: 380px;
  }
  @media (max-width: 1024px) {
    & ${ButtonContainer} {
      max-width: 766px;
      padding-right: 10px;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }
  }
`;
//mobile
export const ServiceLineupInnerContainerMobile = styled(Box)`
  width: 80%;
`;
export const ServiceLineUpImageContainerMobile = styled.div`
  position: absolute;
  z-index: 0;
  right: 0px;
  top: 0px;
  width: 100%;
  height: calc(min(100%, 126px));
  display: flex;
  justify-content: end;
`;
export const ServiceLineUpImageMobile = styled.div`
  position: relative;
  max-width: 260px;

  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  margin-left: auto;
  background-attachment: scroll !important;
  border-top-right-radius: 16px;
  right: ${(props) =>
    props?.rightMobile !== undefined ? props.rightMobile : "0px"};

  height: calc(
    100% +
      ${(props) => (props?.topMobile !== undefined ? props.topMobile : "0px")}
  );
  width: ${(props) =>
    props?.imgWidthMobile !== undefined ? props.imgWidthMobile : "91px"};
`;
export const ServicesLineUpTextMobile = styled.div`
  color: ${(props) => props?.theme?.colors.secondTextColor};
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  padding-top: 10px;
  padding-right: 15px;
  ${Highlight} {
    line-height: unset;
  }

  @media (max-width: 1024px) {
    height: unset;
    padding: 0 10px 0 0;

    ${Highlight} {
      font-weight: 400;
      letter-spacing: 0.0893506px;
      line-height: 150%;
    }
  }
`;
export const ServiceLineInnerMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 337px;
  width: 260px;
  position: relative;
`;
