import styled from "styled-components";
export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 49px;
  @media screen and (max-width: 1200px) {
    margin-bottom: 25px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 5px;
  }
`;
export const AboutIntro = styled.p`
  text-align: left;
  padding-bottom: 90px;
  font-size: 24px;
  font-weight: 400;

  margin: 32px 10px 0;
  letter-spacing: -0.5px;
  line-height: 150%;

  @media screen and (max-width: 1200px) {
    margin: 0px 10px;
    font-size: 18px;
  }
  @media screen and (max-width: 768px) {
    padding-bottom: 38px;
    line-height: 24px;
    font-size: 16px;
    letter-spacing: unset;
  }
  @media screen and (max-width: 479px) {
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
  }
`;
export const SectionWrapper = styled.h2`
  background-color: #040a1e;
  height: auto;
  padding-top: 100px;
  margin-block: 0px;
`;
