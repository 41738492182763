import React, { useEffect } from "react";
import introAbout from "../../static/intro-about.jpg";
import { Trans, useTranslation } from "react-i18next";
import { AboutContainer, AboutIntro } from "./About.styled";
import {
  ImageContainer,
  ImageElementSimple,
} from "components/Image/Image.styled";
import { Highlight } from "../../components/Hightlight/Hightlight.style";
import Commitments from "components/Commitment/Commitments";
import SubSections from "components/SubSection/SubSections";
import Section from "../../components/Section/Section";
import { TitleHighlight } from "components/Section/Section.styled";
import CoreTeam from "components/Team/CoreTeam";
import BusinessTeam from "components/Team/BusinessTeam";
import PeopleTeam from "components/Team/PeopleTeam";
import TechTeam from "components/Team/TechTeam";
import i18next from "i18next";
import useDevice from "hooks/useDevice";
const AboutPage = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = i18next.t("pageTitles.About");
  }, []);
  const { isMobile } = useDevice();
  return (
    <>
      <Section
        isStyled={true}
        bgColor="white"
        titleAlignLeft
        cardTitle={
          <Trans
            t={t}
            components={[<TitleHighlight isStyled={true} key={0} />]}
            i18nKey="aboutPage.title"
          />
        }
        margin={isMobile ? "93px 0px 32px" : "170px 10px 39px"}
        paddingBottom={isMobile ? "10px" : "32px"}
      >
        <AboutContainer>
          <AboutIntro>
            <Trans
              t={t}
              i18nKey="aboutPage.intro"
              components={[
                <Highlight color="thertiary" key={0} isText={true} />,
              ]}
            />
          </AboutIntro>
          <ImageContainer>
            <ImageElementSimple src={introAbout} />
          </ImageContainer>
        </AboutContainer>
      </Section>
      <Section
        bgColor="dark"
        cardTitle={
          <Trans
            t={t}
            i18nKey="aboutPage.Mission1Title"
            components={[
              <TitleHighlight isStyled={true} key={0} />,
              <TitleHighlight isStyled={true} key={1} />,
            ]}
          />
        }
        titleAlignLeft={false}
        margin={isMobile ? "67px 0px 5px" : "110px 10px 67px"}
        paddingBottom={isMobile ? "9px" : "24px"}
      >
        <SubSections applyPadding={false} IsBold={true} />
      </Section>
      <Section
        bgColor="dark"
        cardTitle={
          <Trans
            t={t}
            components={[<TitleHighlight isStyled={true} key={0} />]}
            i18nKey="aboutPage.commitmentTitle"
          />
        }
        margin={isMobile ? "57px 0px 7px" : "140px 10px 34px"}
        paddingBottom={isMobile ? "8px" : "22px"}
      >
        <Commitments />
      </Section>
      <CoreTeam />
      <BusinessTeam />
      <PeopleTeam />
      <TechTeam />
    </>
  );
};

export default AboutPage;
