export const secondaryThemeColors = {
  primary: "#316DB3",
  secondary: "#b1c9ea",
  secondaryOpaque: "#74A3FF",
  thertiary: "#fcff74",
  white: "#fefefe",
  gray: "#adadad",
  dark: "#040a1e",
  textColor: "#040a1e",
  secondTextColor: "#fff",
  thirdTextColor: "#040a1e", //"#333",
  red: "#f00",
  cardTextColor: "#040a1e",
  secondGray: "#aaa",
  headerColor: "#040a1e", //"#050505",
  subTitleColor: "#040a1e", //"#080f25",
};
