import { Box, Grid } from "@mui/material";
import styled from "styled-components";
import { SubSectionContainerText } from "components/SubSection/SubSection.styled";
import { Highlight } from "components/Hightlight/Hightlight.style";
import { LinkContainer } from "components/Button/ActionButton/ActionButton.styled";
import Wrapper from "assets/images/svg/vectors_wrapper_top_left_w.svg";
import imgBanner from "assets/images/pages/home-banner.jpg";
import {
  HighlightLinkContener,
  HighlightText,
  Link,
} from "components/Hightlight/Hightlight.style";

export const HomeBannerContainer = styled(Box)`
  margin-top: 160px;
  margin-bottom: 80px;
  grid-row-gap: 22px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-decoration: none;
  display: flex;
  position: relative;

  @media (max-width: 768px) {
    margin: 76px 0 32px;
  }
`;

export const HomeBannerImageParagraph = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    height: 255px;
  }
`;

export const HomeBannerParagraph = styled(Box)`
  max-width: 750px;
  left: 0;
  position: absolute;
  z-index: 1;
  width: 100%;
`;

export const HomeBannerParagraphHeader = styled(Box)`
  border-bottom-right-radius: 16px;
  font-size: 72px;
  display: table;
  font-stretch: expanded;
  letter-spacing: -1px;
  color: ${(props) => props?.theme.colors?.headerColor};
  background-color: ${(props) => props?.theme.colors?.white};
  width: auto;
  padding: 0 18px 0px 0;

  font-weight: 300;

  @media (max-width: 768px) {
    font-size: 28px;
  }

  &:after {
    transform: translateX(17.5px);
    position: absolute;
    z-index: -1;
    content: "";
    background-image: url(${Wrapper});
    background-size: cover;
    background-position: center;

    height: 20px;
    width: 20px;

    @media (max-width: 768px) {
      background: none;
    }
  }

  @media (max-width: 768px) {
    padding: 0 12px 0 0;
    border-bottom-right-radius: 6px;
    letter-spacing: -0.394256px;
    line-height: 32.33px;
    font-weight: 400;
  }

  @media (max-width: 330px) {
    letter-spacing: -1px;
  }
`;

export const HomeBannerParagraphText = styled(Box)`
  //margin: 0 auto 0 0;
  max-width: 100%;
  display: flex;
  flex-direction: row;

  & ${HighlightLinkContener}, & ${HighlightText}, & ${Link} {
    width: 100%;
    max-width: 370px;

    font-size: 24px;
    font-weight: 400;
    line-height: 150%;
  }

  & ${SubSectionContainerText}, & ${HighlightLinkContener} {
    background-color: ${(props) => props?.theme.colors?.white};

    padding: 0px 8px 4px 8px;
    margin: 0;
  }

  & ${HighlightText} {
    margin-top: 8px;
    margin-left: -2px;
  }

  @media (max-width: 768px) {
    justify-content: center;
    & ${HighlightLinkContener} {
      font-size: 16px;
      line-height: 24px;
      margin-top: 90px;
      padding: 0 10px 0 0;
    }

    & ${Highlight}, & ${HighlightText}, & ${Link}, & ${HighlightLinkContener} {
      font-size: 16px;
      max-width: 95%;
      line-height: 24px;
    }

    & ${HighlightText} {
      margin-top: 4px;
      margin-left: -2px;
    }
  }

  @media (max-width: 512px) {
    & ${Highlight} {
      display: inline;
    }

    & ${HighlightLinkContener} {
      padding: 0;
    }
  }

  @media (max-width: 330px) {
    & ${HighlightLinkContener} {
      margin-top: 80px;
    }
  }
`;

export const HomeBannerImage = styled(Box)`
  border-radius: 16px;
  height: 465px;
  width: 800px;
  background-image: url(${imgBanner});
  background-size: cover;
  background-position: center;

  @media (max-width: 768px) {
    height: 130px;
    width: 100%;
    border-radius: 6px;
    margin-top: 25px;
  }
`;

export const Bold = styled.span`
  font-weight: 800;
  font-stretch: expanded;
`;

export const HomeBannerParagraph2 = styled(Box)`
  gap: 25px;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    margin: 25px 0 0;
    gap: 10px;
  }
`;
export const HomeBannerSegment1 = styled(Grid)`
  background-color: ${(props) => props?.theme.colors?.secondaryOpaque};
  border-radius: 16px;
  padding: 6px 14px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 766px;

  @media (max-width: 768px) {
    border-radius: 6px;
    padding: 15px 20px;
    justify-content: space-between;
  }
`;
export const HomeBannerSegment2 = styled(Box)`
  & ${LinkContainer} {
    min-width: 379px;
    height: 100%;

    display: flex;
    width: 100%;
    max-width: initial; //380px;
  }

  @media (max-width: 768px) {
    & ${LinkContainer} {
      min-width: 100%;
      max-width: 766px;
      letter-spacing: 0.864687px;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      gap: 3px;

      border-radius: 6px;
    }
  }
`;

export const HomeBannerSegmentItem = styled(Grid)`
  display: flex;
  flex-direction: column;

  color: ${(props) => props?.theme.colors?.white};
  font-size: 14px;
  font-weight: 500;
  line-height: 150.5%;
  text-align: center;
  font-stretch: extra-expanded;
  justify-content: center;

  & div {
    color: ${(props) => props?.theme.colors?.white};
    letter-spacing: -2.56402px;
    font-weight: 300;
    line-height: 70.51px;
  }

  @media (max-width: 768px) {
    & div {
      font-size: 26px;
      letter-spacing: -1.1486px;
      font-size: 26px;
      font-weight: 400;
      line-height: 31.59px;
    }

    font-size: 12px;
  }
`;

export const HomeBannerBreadcrumb = styled(Box)`
  display: flex;
  flex-direction: row;
  padding: 16px 20px;
  justify-content: center;

  background-color: ${(props) => props?.theme.colors?.thertiary};
  border-radius: 40px;
  position: absolute;
  top: 380px;
  left: 400px;

  @media (max-width: 768px) {
    padding: 5px 9px;
    top: 135px;
    width: 180px;
    height: 36px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const HomeBannerBreadcrumbItem = styled(Box)`
  color: ${(props) => props?.theme.colors?.black};
  letter-spacing: 1.58317px;
  margin-top: 0;
  margin-bottom: 0;
  height: 32px;

  padding: 3.5px 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 25.33px;
  font-stretch: expanded;
  text-decoration: none;
  border-right: 1px solid black;

  &:last-child {
    border-right: none;
  }

  @media (max-width: 768px) {
    font-size: 11px;
    padding: 3px 3px;
    margin: auto;

    letter-spacing: 1.58317px;

    font-stretch: normal;
    height: 18px;
    line-height: initial;
  }
`;
