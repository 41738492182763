import React from "react";
import velimir_djordjevic from "../../static/velimir_djordjevic.png";
import milos_mandic from "../../static/milos_mandic.png";
import milan_tripkovic from "../../static/milan_tripkovic.png";
import { BackgroundType } from "constants/backgroundType";
import i18next from "i18next";
import Team from "./Team";
import useDevice from "hooks/useDevice";
const CoreTeam = () => {
  const { isMobile } = useDevice();
  const team = [
    {
      title: i18next.t("aboutPage.ourTeam.Core.Velimir"),
      subTitle: i18next.t("aboutPage.ourTeam.Core.Role"),
      image: velimir_djordjevic,
      backgroundType: BackgroundType.DARK_BLUE,
    },
    {
      title: i18next.t("aboutPage.ourTeam.Core.Milos"),
      subTitle: i18next.t("aboutPage.ourTeam.Core.Role"),
      image: milos_mandic,
      backgroundType: BackgroundType.BLUE,
    },
    {
      title: i18next.t("aboutPage.ourTeam.Core.Milan"),
      subTitle: i18next.t("aboutPage.ourTeam.Core.Role"),
      image: milan_tripkovic,
      backgroundType: BackgroundType.YELLOW,
    },
  ];
  return (
    <Team
      margin={isMobile ? "64px 0 10px" : "99px 10px 0px"}
      paddingBottom={isMobile ? "10px" : "20px"}
      title="aboutPage.ourTeam.Core.Title"
      team={team}
    />
  );
};
export default CoreTeam;
