import React, {useEffect, useState} from 'react';
import { TextareaContainer } from './CustomForm.style'
import PropTypes from "prop-types";
const Textarea = (props) => {
  const [text, setText] = useState('');
  const { isSubmited } = props;
  const handleChange = (event) => {
    const newText = event.target.value;
    setText(newText);
    props.onTextareaChange(newText);
  };
  useEffect(() => {
    setText('');
  }, [isSubmited])
    return (
      <TextareaContainer value={text} onChange={(e) => handleChange(e)} ></TextareaContainer>
    );
}
Textarea.propTypes = {
  onTextareaChange: PropTypes.func,
  isSubmited: PropTypes.bool
};
export default Textarea;
