import { Box } from "@mui/material";
import styled from "styled-components";

export const HeaderNavigationContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  height: 100%;
  width: 100%;
  gap: 3px;
`;
